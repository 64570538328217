
export const planteCatNames = [
    '____________',
    'Annuelles et bisannuelles',
    'Arbres',
    'Arbres et arbustes fruitiers',
    'Arbustes',
    'Bambous',
    'Gazon',
    'Graines & Bulbes',
    'Plantes de haie',
    'Plantes potagères & aromatiques',
    'Rosiers',
    'Vivace herbacée',
    'Fleurs vivaces a feuilles persistantes',
    'Grimpant a feuilles persistantes',
];
