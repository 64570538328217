import React, {useState} from 'react';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardHeader from "react-bootstrap/CardHeader";
import './Notification.css';
import Login from "../Login/Login";
import LoaderNotif from "../../partials/LoaderNotif";
import useToken from "../App/useToken";
import TopAppBar from "../../partials/TopAppBar";



function NotifDetails() {


    const {token,} = useToken(false);
    const [is_loading] = useState(false);


    if (!token) {
        return <Login/>
    }
    return (
        <div className="home-wrapper">
            <TopAppBar/>
            <div  style={{marginTop: 0   }}>
            <Card style={{paddingTop: 20 }}>
                    <CardHeader>
                         <h3> Notif Details </h3>
                    </CardHeader>
                   <CardContent>
                    {!is_loading
                        ? <></>
                        : <LoaderNotif/>
                    }
                    </CardContent>
            </Card>

            </div>


        </div>
    );

}

export default NotifDetails;
