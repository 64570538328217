import * as React from 'react';
import {useEffect} from "react";
import useToken from "../App/useToken";
import Login from "../Login/Login";
import useNotifications from "../App/useNotifications";
import './Notification.css';
import Avatar from "@mui/material/Avatar";
import {styled, Theme} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Grid } from "@mui/joy";
import {Divider,  Paper} from "@mui/material";
import OpacityIcon from "@mui/icons-material/Opacity";
import SoapIcon from "@mui/icons-material/Soap";
import { ReactComponent as RempoterIcon } from "../../assets/Rempoter.svg";
import { ReactComponent as BrumisationIcon } from "../../assets/Brumisation.svg";
import {makeStyles, withStyles} from "@mui/styles";
import AddRappel from "./AddRappel";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import MuiDialogContent from "@mui/material/DialogContent";
import moment from 'moment';
import 'moment/locale/fr';
import LoaderNotif from "../../partials/LoaderNotif";
import Button from "@mui/material/Button";
import AddERappel from "./AddERappel";

const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    maxWidth: 380,
    color: theme.palette.text.primary,
}));

const useStyles = makeStyles(() => {
    return {
        container: {
            padding: '2em'
        },
        field: {
            display: 'block',
            marginTop: '1em',
            marginBottom: '1em'
        },
        notif_divider: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            backgroundColor: '#f5fbf9'
        }
    }
});

const DialogTitle = ((props: DialogTitleProps) => {
    const {children, onClose, onValidate, ...other} = props;
    return (
        <MuiDialogTitle style={{marginLeft: '30px', padding: '10px', lineHeight: 1}} {...other}>
            <Typography variant="div">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" style={{position: 'absolute', left: 2, top: 2, color: 'gray',}}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
            {onValidate ? (
                <IconButton aria-label="Check" style={{position: 'absolute', right: 2, top: 2, color: 'green',}}
                            onClick={onValidate}>
                    <CheckIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 2,
    },
}))(MuiDialogContent);

const dialogStyle = {backgroundColor: '#ffffff'}

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    onValidate: () => void;
}

function NotifList({Notifs,HasNotifs }) {
    const notifs = Notifs;
    const has_notifs = HasNotifs;

    const {setNotifications} = useNotifications();
    const [open, setOpen] = React.useState(false);
    const [opene, setOpene] = React.useState(false);
    const [notif_title, setNotifTitle] = React.useState(false);
    const [popup_notif, setPopupNotif] = React.useState(false);
    const {token} = useToken();
    const classes = useStyles();

    useEffect(() => {
        setNotifications(notifs);
    }, []);

    if (!token) {
        return <Login/>
    }

    const handleClickOpen = (e, notif) => {
        setOpen(true);
        setNotifTitle(notif.name);
        setPopupNotif(notif);
    };

    const handleClose = () => {
        setOpen(false);
        setOpene(false);
    };


    return (

        <>

            <Divider component={'div'}  variant="middle" style={{ width: '90%', marginTop:15, marginBottom:15   }} />
            <div className="container notif-content" style={{marginTop: 20}}>
                <div style={{width: '100%', maxWidth: '100%'}}  >
                    <div className="line"/>
                    <div className="notification-content">
                        {has_notifs

                            ? notifs.map((notif, index) => {
                                let icontype='simple';
                                switch(notif.icon){
                                    case 'RempoterIcon':
                                        icontype='perso';
                                        break
                                    case 'BrumisationIcon':
                                        icontype='perso';
                                        break
                                    case 'SoapIcon':
                                        icontype='simple';
                                        break
                                    case 'OpacityIcon':
                                        icontype='simple';
                                        break
                                    default:
                                        icontype='simple';
                                    break

                                }


                                return (
                                    <div className="content-spacing" key={index}>
                                        <span className="circle"/>
                                        <Box sx={{flexGrow: 1, overflow: 'hidden', px: 0.5}}>
                                            <StyledPaper sx={{my: 0.2, mx: 'auto', p: 0.5,}}
                                                         style={{backgroundColor: '#f5fbf9'}}>


                                                <Grid container wrap="nowrap" spacing={1}>
                                                    {icontype==='simple'
                                                        ? <><Grid
                                                            hidden={(notif.icon !== 'SoapIcon')}>{(notif.icon === 'SoapIcon') ?
                                                            <SoapIcon/> : ''}</Grid>
                                                          <Grid
                                                            hidden={(notif.icon !== 'OpacityIcon')}>{(notif.icon === 'OpacityIcon') ?
                                                            <OpacityIcon/> : ''}</Grid>
                                                        </>
                                                        :<><Grid
                                                            hidden={(notif.icon !== 'RempoterIcon')}>{(notif.icon === 'RempoterIcon') ?
                                                            <RempoterIcon style={{width:25, height:25}} /> : ''}</Grid>
                                                        <Grid
                                                            hidden={(notif.icon !== 'BrumisationIcon')}>{(notif.icon === 'BrumisationIcon') ?
                                                            <BrumisationIcon style={{width:25, height:25}} /> : ''}</Grid>
                                                        </>
                                                    }

                                                    <Grid item xs zeroMinWidth>
                                                        <Typography style={{
                                                            padding: '5px 10px ',
                                                            color: '#2d6657',
                                                            fontSize: '16px'
                                                        }}><b>{notif.ActionRequired}</b></Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button onClick={event => handleClickOpen(event, notif)}
                                                                size={'medium'} style={{
                                                            backgroundColor: (parseInt(notif.status)) ? '#4caf50' : '#ffe797',
                                                            fontSize: '11px',
                                                            padding: '5px 10px',
                                                            color: '#000000'
                                                        }}>{(parseInt(notif.status)) ? 'Rappel' : 'Terminer'}</Button>

                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs zeroMinWidth>
                                                        <div className={classes.notif_divider}>
                                                            <Divider component="div"/>
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container wrap="nowrap" spacing={0.5}>
                                                    <Grid item>
                                                        <Avatar alt={notif.ActionRequired} src={notif?.image}/>
                                                    </Grid>
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography style={{
                                                            fontSize: '12px',
                                                            padding: '0px'
                                                        }}>{notif.plant_name}</Typography>
                                                        <Typography style={{
                                                            textAlign: 'right ',
                                                            color: '#c53542',
                                                            fontSize: 12,
                                                            padding: '0px'
                                                        }}>{moment(notif.date_notif).startOf('seconds').fromNow()}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </StyledPaper>
                                        </Box>
                                    </div>
                                )
                            })
                            : <LoaderNotif/>
                        }
                    </div>
                </div>


            </div>

            <div style={dialogStyle}>
                <Dialog style={{backgroundColor:'#e0ede1'}} aria-labelledby="customized-dialog-title" id='custommodal-dialog' open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose} onValidate={''} >
                        Nouveau Rappel
                    </DialogTitle>
                    <DialogContent dividers  >
                        <Typography style={{
                            fontSize: 16,
                            textAlign: 'center',
                            padding: '0px',
                            fontWeight: 700
                        }}>{notif_title}</Typography>
                        <AddRappel Notif={popup_notif}/>
                    </DialogContent>

                </Dialog>
            </div>
            <div style={dialogStyle}>
                <Dialog style={{backgroundColor:'#e0ede1'}} aria-labelledby="customized-dialog-title" id='custommodal-dialog' open={opene}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose} onValidate={''} >
                        Nouveau Rappel
                    </DialogTitle>
                    <DialogContent dividers  >
                        <Typography style={{
                            fontSize: 16,
                            textAlign: 'center',
                            padding: '0px',
                            fontWeight: 700
                        }}>{notif_title}</Typography>
                        <AddERappel Notif={popup_notif}/>
                    </DialogContent>

                </Dialog>
            </div>
        </>
    );
}

export default NotifList;
