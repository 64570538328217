import React, {useState} from 'react';
import {Button, Box, Divider, FormControl, TextField} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import logo from "../../assets/logo_new.svg";
import useUserData from "../App/useUserData";
import useToken from "../App/useToken";
import Home from "../Home/Home";
import API from "../../api";
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const useStyles = makeStyles(() => {
    return {
        container: {
            padding: '2em'
        },
        field: {
            display: 'block',
            marginTop: '1em',
            marginBottom: '1em'
        },
        divider: {
            marginTop: '40px',
            marginBottom: '1em'
        }
    }
})

async function loginUser(credentials) {
    return await API.post(`login`, credentials).then(res => res.data);
}

function Login() {

    const {setUser} = useUserData();
    const {token , setToken} = useToken();
    const classes = useStyles();
    const [formValues, setFormValues] = useState({
        username: {
            value: '',
            defaultValue: 'email',
            error: false,
            errorMessage: 'You must enter an email'
        },
        password: {
            value: '',
            defaultValue: 'password',
            error: false,
            errorMessage: 'You must enter a password'
        }
    })
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value
            }
        })
    }
    //const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}

        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if (currentValue === '') {
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }

        }

        setFormValues(newFormValues);
         await loginUser(newFormValues).then(user=>{
            if (user.token && user.data) {
                const token = user.token;
                const userdata = user.data;
                setUser(userdata);
                setToken(token);
            }
        }).finally(user => {
              refreshPage();
         });
    }
    if (token) {
        return <Home/>
    }
    const refreshPage = () => {
        return <Home/>
    }
    return (
        <div className="form-wrapper">

            <div className="logo_section"><Link to="/home"><img src={logo} className="App-logo" alt="logo"/></Link>
            </div>
            <div className={'container'} style={{width: '90%',backgroundColor: 'rgb(225 231 202)',  padding: '10px' }} >
                <h1>Se connecter</h1>
                <p>Avec votre adresse e-mail</p>
                <div className={classes.divider}>
                    <Divider component="div"/>
                </div>

                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1, width: '25ch'},
                     }}
                     autoComplete="off" onSubmit={handleSubmit}
                     noValidate
                >
                    <div>
                        <TextField className="inputAddText" required
                                   size="small"
                                   name="username"
                                   type="email"
                                   value={formValues.username.value ?? ''}
                                   label={formValues.username.defaultValue}
                                   onChange={handleChange}
                                   error={formValues.username.error}
                                   helperText={formValues.username.error && formValues.username.errorMessage}/>


                        <TextField className="inputAddText" required
                                   size="small"
                                   name="password"
                                   type="password"
                                   value={formValues.password.value ?? ''}
                                   label={formValues.password.defaultValue}
                                   onChange={handleChange}
                                   error={formValues.password.error}
                                   helperText={formValues.password.error && formValues.password.errorMessage}/>


                    </div>
                    <div className={classes.divider}> </div>
                    <FormControl className={classes.field}>
                        <Button variant="contained" color="success" type="submit">Se connecter</Button>
                    </FormControl>

                    <div className={classes.divider}>
                        <Divider component="div"/>
                    </div>
                    <h4>Créer un compte</h4>

                    <div className={classes.divider}> </div>

                    <div>Vous n'avez pas de compte ? <Link className={'link-success'}
                                                           to="/register"><b>S'inscrire</b></Link></div>
                    <div className={classes.divider}> </div>
                </Box>
            </div>

        </div>
    )
}

export default Login;
