import React, {useEffect, useState} from 'react';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {Stack} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {Grid} from "@mui/joy";
import {Item, Itembreadcrumb} from "../../../constants/styled-components";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import API from "../../../api";
import useToken from "../../App/useToken";
import Login from "../../Login/Login";
import TopAppBar from "../../../partials/TopAppBar";
import useGardenData from "../../App/useGardenData";
import useMyPlants from "../../App/useMyPlants";
import Loader from "../../../partials/Loader";
import './ShopProcess.css';
import imgremplacement from "../../../assets/imgremplacement.svg";
import {ListGroupItem} from "react-bootstrap";

function ShopProcess() {
    const {id} = useParams();
    const {token} = useToken();
    const [plant, setPlant] = useState([]);
    const [is_loading, setIsLoading] = useState(true);
    const {garden} = useGardenData();
    const {myplants, setMyPlants} = useMyPlants();
    const [has_plants, setHasPlants] = useState(false);
    const [no_garden, setNoGarden] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                await  API.get(`get_plant?id=${id}`)
                    .then(res => {
                        if (res.data) {
                            setPlant(res.data);
                        }
                    }).finally(res => {
                        setIsLoading(false);
                    });
            }
        }
        fetchData();
    }, [id]);

    if (!token) {
        return <Login/>
    }

    const AddToGardenEventHandler = (plant) => async (e) => {

        await API.post(`add_plant_to_garden`, {plant: plant.id, garden_id: garden.id, type: 'lib'}).then(res => {
            if (res.data.status) {
                setMyPlants(res.data.plants);
                setHasPlants(true);
                navigate(`/plant-details/${plant.id}`);
            } else {

                setNoGarden(true);
                setHasPlants(false);
            }

        });

    }

    return (
        <div className="home-wrapper">
            <TopAppBar/>

            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>{plant.name}</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}}>

                    </Grid>
                </Grid>


            </Stack>


            {!is_loading
                ?
            <><Card>
                <CardMedia
                    component="img"
                    image={plant.thumb} alt={plant.name}
                    height="100"
                    onError={e => {
                        e.target.src = imgremplacement;
                    }}
                />
                <CardContent style={{backgroundColor:'#f6fff1'}}>
                    <ListGroupItem>
                    <Item gutterBottom  style={{ color: 'black',textAlign:'left', marginTop:10 }}>
                       <b> Prix :</b> {plant.prix}DT
                    </Item>

                    <Item gutterBottom   style={{ color: 'black',textAlign:'left', marginTop:10 }}>
                        <b> Type de paiements : </b> en espèces à la livraison
                    </Item>
                   </ListGroupItem>
                    <div  style={{
                        paddingBottom: 30,
                    }} >
                        <Button onClick={AddToGardenEventHandler(plant)}
                            style={{
                                fontSize: '14px',
                                top: 30,
                                borderRadius: 15,
                                backgroundColor: '#eedbad',
                                textTransform: 'inherit',
                                color: '#101711',
                                marginTop: 20,
                                padding: '5px 15px'
                            }} variant="contained"
                            color="success"> AJOUTER A MON PANIER  </Button>
                    </div>
                </CardContent>

            </Card>
            </> :<Loader/>
            }


        </div>
    );

}

export default ShopProcess;
