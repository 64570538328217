import React, {useState} from 'react'
import {Grid, Paper, Button, Typography, InputLabel} from '@mui/material'
import {TextField} from '@mui/material'
import MenuItem from "@mui/material/MenuItem";
import Select  from '@mui/material/Select';
import {FormControl} from "@mui/joy";
import moment from 'moment';
import 'moment/locale/fr';
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import useUserData from "../App/useUserData";
import useMyPlants from "../App/useMyPlants";
import API from "../../api";

//const formatDT = "YYYY-MM-DD HH:mm:ss"
const formatDate = "YYYY-MM-DD"
const formatTime = "HH:mm:ss"



async function addRappel(data,suggest, user_id, plant_id) {
    return  await API.post(`add_notif`,{data,suggest, user_id, plant_id}).then(res => res.jdata);
}

const paperStyle = {padding: '0 0px 40px 0px', backgroundColor:'rgb(224, 237, 225)'}
function AddERappel({Notif={}}) {
    const {user} = useUserData();
    const notification = Notif;
    const {myplants} = useMyPlants();
    const navigate = useNavigate();

    const initialValues = {
        user_id: user?.id,
        plant_id: notification?.plant_id,
        action: notification?.ActionRequired,
        notes: notification?.notes,
        date: moment(notification?.date_notif).format(formatDate) ?? '' ,
        time: moment(notification?.date_notif).format(formatTime) ?? '',
    };
    const initialSValues = {
        days: notification?.suggest ? JSON.parse(notification?.suggest)?.days: '1',
        period: notification?.suggest ?JSON.parse(notification?.suggest)?.period:'jours',
    };
    const [formValues, setFormValues] = useState({
        user_id: {name: 'user_id', value: '', defaultValue: user?.id,  error: false },
        plant_id: {name: 'plant_id', value: '', defaultValue: notification?.plant_id,  error: false },
        action: {name: 'action', value: '', defaultValue: notification?.ActionRequired,  error: false },
        notes: {name: 'notes', value: '', defaultValue: notification?.notes,  error: false },
        date: {name: 'date', value: '', defaultValue: moment(notification?.date_notif).format(formatDate) ?? '',  error: false },
        time: {name: 'time', value: '', defaultValue: moment(notification?.date_notif).format(formatTime) ?? '',  error: false },
    });
    const [formSValues, setFormSValues] = useState({
        days: {name: 'days', value: '', defaultValue: '10',  error: false },
        period: {name: 'period', value: '', defaultValue: 'jours',  error: false }
    });


    const handleChange = (e) => {

        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value
            }
        })

    }
    const handleChangeS = (e) => {

        const {name, value} = e.target;
        setFormSValues({
            ...formSValues,
            [name]: {
                ...formSValues[name],
                value
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}
        const formSFields = Object.keys(formSValues);
        let newFormSValues = {...formSValues}

        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if (currentValue === '') {
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }

        }

        for (let index = 0; index < formSFields.length; index++) {
            const currentSField = formSFields[index];
            const currentSValue = formSValues[currentSField].value;

            if (currentSValue === '') {
                newFormSValues = {
                    ...newFormSValues,
                    [currentSField]: {
                        ...newFormSValues[currentSField],
                        error: true
                    }
                }
            }

        }
        setFormValues(newFormValues);
        setFormSValues(newFormSValues);
        await addRappel(newFormValues,newFormSValues, user.id, newFormValues.plant_id.value).then(rappel=>{
            if (rappel.data) {
                navigate('/notification'  );
               window.location.reload();
            }
        }).finally(res => {
            window.location.reload();
        });
    };


    return (
        <Grid style={paperStyle}>
            <Paper elevation={0} style={paperStyle}>
                <Grid align='center'>
                    <Typography variant='caption'/>
                </Grid>
                <Box component="form"
                     autoComplete="on" onSubmit={handleSubmit}
                     noValidate
                >
                    <div style={{padding: '20px 0'}}>
                        {myplants
                            ?
                        <FormControl  sx={{m: 1, minWidth: 250, margin: '20px 10px'}} size="small">
                            <InputLabel id="plant-multiple-name-label">Plante</InputLabel>
                            <Select required labelId="plant-simple-select-label"
                                    size="small"
                                    name="plant_id"
                                    defaultValue={initialValues.plant_id}
                                    value={formValues.plant_id.value}
                                    label='plante'
                                    onChange={handleChange}>
                                {myplants.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item.id}
                                    >{item.name}</MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                        :''
                    }
                        <FormControl  sx={{m: 1, minWidth: 250, margin: '20px 10px'}} size="small">
                            <InputLabel id="demo-multiple-name-label">Tâche</InputLabel>
                            <Select required labelId="demo-simple-select-label"
                                    size="small"
                                    name="action"
                                    defaultValue={initialValues.action}
                                    value={formValues.action.value}
                                    label='Tâche'
                                    onChange={handleChange}>
                                <MenuItem key='1' value={'Arroser'}>Arroser</MenuItem>
                                <MenuItem key='2' value={'Fertiliser'}>Fertiliser</MenuItem>
                                <MenuItem key='3' value={'Rempoter'}>Rempoter</MenuItem>
                                <MenuItem key='4' value={'Brumisation'}>Brumisation</MenuItem>
                                <MenuItem key='5' value={'Autre'}>Autre</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl  sx={{m: 1, minWidth: 250, margin: '20px 10px'}} size="small">
                            <InputLabel id="demo-multiple-name-label" style={{
                                fontWeight: '700',
                                fontSize: '14px',
                            }}>Date</InputLabel>
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                name="date"
                                onChange={handleChange}
                                inputformat={formatDate} type='date'
                                defaultValue={initialValues.date} />
                        </FormControl>
                        <FormControl  sx={{m: 1, minWidth: 250, margin: '20px 10px'}} size="small">
                         <InputLabel id="demo-multiple-name-label" style={{
                            fontWeight: '700',
                            fontSize: '14px',
                        }}>Time</InputLabel>
                            <TextField
                                id="outlined-size-small"
                                size="small"
                                onChange={handleChange}
                                name="time"
                                inputformat={formatTime} type='time'
                                defaultValue={initialValues.time} />
                        </FormControl>
                        <FormControl  sx={{m: 1, minWidth: 250, margin: '20px 10px'}} size="small">

                            <TextField
                                multiline
                                rows={4}
                                name="notes"
                                onChange={handleChange}
                                id="outlined-size-small"
                                defaultValue={initialValues.notes}
                                label='Notes'
                                size="small"
                            />

                        </FormControl>
                        <FormControl style={{margin: '20px'}}>
                            <InputLabel id="demo-multiple-name-label" style={{
                                color: '#357c76',
                                fontWeight: '700',
                                fontSize: '16px',
                                margin: '20px 0'
                            }}>Tous les</InputLabel>
                            <div style={{display: "flex"}}>
                                <div style={{width: '25%', display: "inline-flex"}}>

                                    <Select required labelId="days-every-select-label"
                                            size="small"
                                            name="days"
                                            defaultValue={initialSValues.days}
                                            value={formSValues.days.value}
                                            label='Days'
                                            onChange={handleChangeS}>
                                        <MenuItem key='0' value={'______'}>______</MenuItem>
                                        <MenuItem key='1' value={'1'}>1</MenuItem>
                                        <MenuItem key='2' value={'2'}>2</MenuItem>
                                        <MenuItem key='3' value={'3'}>3</MenuItem>
                                        <MenuItem key='4' value={'4'}>4</MenuItem>
                                        <MenuItem key='5' value={'5'}>5</MenuItem>
                                        <MenuItem key='6' value={'6'}>6</MenuItem>
                                        <MenuItem key='7' value={'7'}>7</MenuItem>
                                        <MenuItem key='8' value={'8'}>8</MenuItem>
                                        <MenuItem key='9' value={'9'}>9</MenuItem>
                                        <MenuItem key='10' value={'10'}>10</MenuItem>
                                    </Select>
                                </div>
                                <div style={{width: '25%', display: "inline-flex"}}>
                                    <Select required labelId="period-every-select-label"
                                            size="small"
                                            name="period"
                                            defaultValue={initialSValues.period}
                                            value={formSValues.period.value}
                                            label='Period'
                                            onChange={handleChangeS}>
                                        <MenuItem key='0' value={'________'}>________</MenuItem>
                                        <MenuItem key='1' value={'jours'}>jours</MenuItem>
                                        <MenuItem key='2' value={'semaines'}>semaines</MenuItem>
                                        <MenuItem key='3' value={'mois'}>mois</MenuItem>
                                        <MenuItem key='4' value={'années'}>années</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </FormControl>
                        <FormControl  sx={{m: 1, minWidth: 250, margin: '20px 10px'}} size="small">
                            <Button variant='contained' color='primary' type="submit">valider</Button>
                        </FormControl>
                    </div>
                </Box>

            </Paper>
        </Grid>
    )
}

export default AddERappel;
