import {useState} from "react";

export default function useUserData() {
    const getUser = () => {
        const userdataString = localStorage.getItem('userdata');
        return JSON.parse(userdataString)
    };

    const [user, setUser] = useState(getUser());

    const saveUser = userData => {
        localStorage.setItem('userdata', JSON.stringify(userData));
        setUser(userData);
    };

    return {
        setUser: saveUser,
        user
    }
}