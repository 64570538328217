import React, {useState} from 'react';
import {Button, Stack} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import logo from "../../../logov1.png";
import {Link} from "react-router-dom";
import Login from "../../Login/Login";
import TopAppBar from "../../../partials/TopAppBar";
import useMyPlants from "../../App/useMyPlants";
import useToken from "../../App/useToken";
import {useNavigate} from "react-router";
import useUserData from "../../App/useUserData";
import './MyPlants.css';
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../../constants/styled-components";
import Typography from "@mui/material/Typography";

function MyPlants() {

    const {user} = useUserData();
    const {myplants, setMyPlants} = useMyPlants();
    const [gardenid] = useState(user.garden_id);
    const [has_plants, setHasPlants] = useState(null);

    let navigate = useNavigate();

    const {token} = useToken();

    if (!token) {
        return <Login/>
    }

    if (myplants) {
        setHasPlants(true);
    }


    return (

        <div className="home-wrapper">
            <TopAppBar/>
            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>{user.garden?.name}</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}}>

                    </Grid>
                </Grid>


            </Stack>
            <div className="logo_section"><Link to="/home"><img src={logo} className="App-logo" alt="logo"/></Link>
            </div>
            {myplants
                ? <div id="plants_list"> list plants </div>
                : <Button variant="contained" href="/add-plant"> Ajouter plante </Button>
            }



        </div>

    );

}

export default MyPlants;
