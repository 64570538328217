import React, { useState} from 'react';
import {Button, Divider, FormControl, TextField} from "@mui/material";
import Box from '@mui/material/Box';
import {Link, useNavigate} from "react-router-dom";
import logo from "../../assets/logo_new.svg";
import useUserData from "../App/useUserData";
import {makeStyles} from "@mui/styles";
import useToken from "../App/useToken";
import useIsFirst from "../App/useIsFirst";
import API from "../../api";
import './Register.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const useStyles = makeStyles(() => {
    return {
        container: {
            padding: '2em'
        },
        field: {
            display: 'block',
            marginTop: '1em',
            marginBottom: '1em'
        },
        divider: {
            marginTop: '40px',
            marginBottom: '1em'
        },
        dividerA: {
            marginTop: '20px',
            marginBottom: '1em'
        },
        dividerB: {
            marginTop: '15px',
            marginBottom: '1em'
        }
    }
})
function Register() {

    const classes = useStyles();
    const { setUser} = useUserData();
    const { setToken} = useToken();
    const { setIsFirst} = useIsFirst();


    const [formValues, setFormValues] = useState({
        first_name: {
            value: '',
            defaultValue: 'first name',
            error: false,
            errorMessage: 'You must enter a first_name'
        },
        last_name: {
            value: '',
            defaultValue: 'last name',
            error: false,
            errorMessage: 'You must enter a last_name'
        },
        username: {
            value: '',
            defaultValue: 'email',
            error: false,
            errorMessage: 'You must enter a email'
        },
        password: {
            value: '',
            defaultValue: 'password',
            error: false,
            errorMessage: 'You must enter a password'
        }
    })
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value
            }
        })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}

        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if (currentValue === '') {
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }

        }
        setFormValues(newFormValues);
        const user = await API.post(`register`, newFormValues).then(res =>{return res.data});
        if (user.status) {
            const userdata = user.data;
            const token = user.token;
            setUser(userdata);
            setToken(token);
            setIsFirst(true);
            navigate('/account'  );

        }

    }

    return (
        <div className="form-wrapper">
            <div className="logo_section">
                <Link to="/home"><img src={logo} className="App-logo" alt="logo"/></Link>
            </div>
            <div className={'container'}  style={{width: '90%',backgroundColor: 'rgb(225 231 202)',  padding: '10px' }} >
            <h1>S'inscrire</h1>
            <p>Ne perdez jamais l'accés à vos plantes et vos rappels  de soins</p>
            <div className={classes.dividerA}>
                <Divider component="div"/>
            </div>
            <Box component="form" sx={{
                     '& .MuiTextField-root': {m: 1, width: '25ch'},
                 }} autoComplete="off" onSubmit={handleSubmit} noValidate >
                <div>

                    <TextField className="inputAddText" required
                               size="small"
                               name="first_name"
                               type="email"
                               value={formValues.first_name.value ?? ''}
                               label={formValues.first_name.defaultValue}
                               onChange={handleChange}
                               error={formValues.first_name.error}
                               helperText={formValues.first_name.error && formValues.first_name.errorMessage}/>

                    <TextField className="inputAddText" required
                               size="small"
                               name="last_name"
                               type="email"
                               value={formValues.last_name.value ?? ''}
                               label={formValues.last_name.defaultValue}
                               onChange={handleChange}
                               error={formValues.username.error}
                               helperText={formValues.last_name.error && formValues.last_name.errorMessage}/>

                    <TextField className="inputAddText" required
                               size="small"
                               name="username"
                               type="email"
                               value={formValues.username.value ?? ''}
                               label={formValues.username.defaultValue}
                               onChange={handleChange}
                               error={formValues.username.error}
                               helperText={formValues.username.error && formValues.username.errorMessage}/>


                    <TextField className="inputAddText" required
                               size="small"
                               name="password"
                               type="password"
                               value={formValues.password.value ?? ''}
                               label={formValues.password.defaultValue}
                               onChange={handleChange}
                               error={formValues.password.error}
                               helperText={formValues.password.error && formValues.password.errorMessage}/>
                </div>
                <div className={classes.divider}> </div>
                <FormControl className={classes.field}>
                    <Button  variant="contained" color="success" type="submit">S'inscrire</Button>
                </FormControl>
            </Box>
            <div className={classes.dividerA} >
                <Divider component="div"   />
            </div>
            <div>Vous avez déjà un compte ? <Link className={'link-success'} to="/login"><b>Se connecter</b></Link></div>
                <div className={classes.divider}> </div>
             </div>
        </div>
    )
}

export default Register;
