import loader from "../assets/happy-party.gif";
import React from "react";

function Loader() {
    return (
        <div style={{marginTop: 20}}>
            <img src={loader} alt="loader" width="390" height="321" />
        </div>
    );
}


export default Loader;


