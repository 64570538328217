import {useState} from 'react';

export default function useNotifications() {
    const getNotifications = () => {
        const notificationsString = localStorage.getItem('notifications');
        return JSON.parse(notificationsString)
    };

    const [notifications, setNotifications] = useState(getNotifications());

    const saveNotifications = Notifications => {
        localStorage.setItem('notifications', JSON.stringify(Notifications));
        setNotifications(Notifications );
    };

    return {
        setNotifications: saveNotifications,
        notifications
    }
}
