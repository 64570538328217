import React  from 'react';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Grid} from "@mui/joy";
import {Button, Divider,  Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Itembreadcrumb, Item } from "../../../constants/styled-components";
import useToken from "../../App/useToken";
import Login from "../../Login/Login";
import TopAppBar from "../../../partials/TopAppBar";
import useGardenData from "../../App/useGardenData";
import {useNavigate} from "react-router";
import AddDemandePaysagistBtn from "../AddDemandePaysagistBtn";
import './Details.css';

function Details() {

    const {garden } = useGardenData();
    const {token} = useToken();

    let navigate = useNavigate();
    if (!token) {
        return <Login/>
    }
    return (
        <div className="home-wrapper">
            <TopAppBar/>

            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>{garden.name}</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}}>

                    </Grid>
                </Grid>


            </Stack>
            <div style={{marginTop: 20}}>
            {garden
                ?<Card >
                    <CardContent>
                       <h4>Fiche</h4>
                        <Divider component={'div'}  variant="middle" style={{ width: '90%', marginTop:15, marginBottom:15   }} />
                        <Stack spacing={2}>
                            <Item> <b>Superficie :</b> {garden.superficie}</Item>
                            <Item> <b>Orientation :</b> {garden.orientation}</Item>
                            <Item> <b>Type de Sol :</b> {garden.type_sol}</Item>
                            <Item> <b>Style :</b> {garden.style}</Item>
                            <Item> <b>Forme :</b> {garden.forme}</Item>

                        </Stack>


                    </CardContent>
                </Card>
                : ""
            }
            </div>

            <AddDemandePaysagistBtn />

        </div>
    );

}

export default Details;
