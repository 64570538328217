import React from 'react';
import './assets/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/Home/Home";
import MyGarden from "./components/MyGarden/MyGarden";
import Library from "./components/Library/Library";
import Marketplace from "./components/Marketplace/Marketplace";
import AddGarden from "./components/MyGarden/AddGarden/AddGarden";
import Register from "./components/Register/Register";
import Login from "./components/Login/Login";
import ReactDOM from 'react-dom/client';
import AddPlant from "./components/MyGarden/AddPlant/AddPlant";
import MyPlants from "./components/MyGarden/MyPlants/MyPlants";
import PlantDetails from "./components/MyGarden/PlantDetails/PlantDetails";
import LibPlantDetails from "./components/Library/LibPlantDetails/LibPlantDetails";
import ShopPlantDetails from "./components/Marketplace/ShopPlantDetails/ShopPlantDetails";
import Details from "./components/MyGarden/Details/Details";
import Account from "./components/Account/Account";
import Notification from "./components/Notification/Notification";
import CalEntretien from "./components/MyGarden/CalEntretien/CalEntretien";
import ShopProcess from "./components/Marketplace/ShopProcess/ShopProcess";
import NotifDetails from "./components/Notification/NotifDetails";
import IdeasAndTips from "./components/IdeasAndTips/IdeasAndTips";
import DemandePaysagist from "./components/DemandePaysagist/DemandePaysagist";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <div className="app-wrapper">
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route index element={<App/>}/>

                    <Route exact path='/' element={< Home/>}/>
                    <Route exact path='/home' element={< Home/>}/>
                    <Route exact path='/login' element={< Login/>}/>
                    <Route exact path='/register' element={< Register/>}/>
                    <Route exact path='/account' element={< Account/>}/>
                    <Route exact path='/notification' element={< Notification/>}/>
                    <Route exact path='/notif-details/:id' element={< NotifDetails/>}/>
                    <Route exact path='/my-garden' element={< MyGarden/>}/>
                    <Route exact path='/add-garden' element={< AddGarden/>}/>
                    <Route exact path='/garden-details' element={< Details/>}/>
                    <Route exact path='/my-plants' element={< MyPlants/>}/>
                    <Route exact path='/plant-details/:id' element={<PlantDetails/>}/>
                    <Route exact path='/libplant-details/:id' element={<LibPlantDetails/>}/>
                    <Route exact path='/shopplant-details/:id' element={<ShopPlantDetails/>}/>
                    <Route exact path='/add-plant' element={< AddPlant/>}/>
                    <Route exact path='/cal-entretien' element={< CalEntretien/>}/>
                    <Route exact path='/library' element={< Library/>}/>
                    <Route exact path='/marketplace' element={< Marketplace/>}/>
                    <Route exact path='/Shop_process/:id' element={< ShopProcess/>}/>
                    <Route exact path='/ideas-and-tips' element={< IdeasAndTips/>}/>
                    <Route exact path='/demande-paysagist' element={< DemandePaysagist/>}/>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>

    </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
