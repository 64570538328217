import {useState} from 'react';

export default function useShopPlants() {
    const getShopPlants = () => {
        const shopplantsString = localStorage.getItem('shop_plants');
        if( JSON.parse(shopplantsString) ===[]) return null;
        return JSON.parse(shopplantsString)
    };

    const [shopplants, setShopPlants] = useState(getShopPlants());

    const saveShopPlants = userShopPlants => {
        localStorage.setItem('shop_plants', JSON.stringify(userShopPlants));
        setShopPlants(userShopPlants );
    };

    return {
        setShopPlants: saveShopPlants,
        shopplants
    }
}
