import {useState} from 'react';

export default function useMyPlants() {
    const getMyPlants = () => {
        const myplantsString = localStorage.getItem('my_plants');
        return JSON.parse(myplantsString)
    };

    const [myplants, setMyPlants] = useState(getMyPlants());

    const saveMyPlants = userMyPlants => {
        localStorage.setItem('my_plants', JSON.stringify(userMyPlants));
        setMyPlants(userMyPlants );
    };

    return {
        setMyPlants: saveMyPlants,
        myplants
    }
}