import React, {useEffect} from 'react';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import {Stack} from "@mui/material";
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../constants/styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useToken from "../App/useToken";
import TopAppBar from "../../partials/TopAppBar";
import Login from "../Login/Login";
import LoaderNotif from "../../partials/LoaderNotif";
import NotifList from "./NotifList";
import API from "../../api";
import useUserData from "../App/useUserData";
import useNotifications from "../App/useNotifications";
import { Theme} from '@mui/material/styles';
import AddERappel from "./AddERappel";
import Dialog from "@mui/material/Dialog";
import { withStyles} from "@mui/styles";
import MuiDialogContent from "@mui/material/DialogContent";
import useMyPlants from "../App/useMyPlants";
import {useNavigate} from "react-router";
import './Notification.css';

const DialogTitle = ((props: DialogTitleProps) => {
    const {children, onClose, onValidate, ...other} = props;
    return (
        <MuiDialogTitle style={{marginLeft: '30px', padding: '10px', lineHeight: 1}} {...other}>
            <Typography variant="div">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" style={{position: 'absolute', left: 2, top: 2, color: 'gray',}}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
            {onValidate ? (
                <IconButton aria-label="Check" style={{position: 'absolute', right: 2, top: 2, color: 'green',}}
                            onClick={onValidate}>
                    <CheckIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 2,
    },
}))(MuiDialogContent);

const dialogStyle = {backgroundColor: '#ffffff'}

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    onValidate: () => void;
}

function Notification() {


    const {token} = useToken(false);
    const {notifications, setNotifications} = useNotifications([]);
    const {user} = useUserData();
    const {myplants, setMyPlants} = useMyPlants();
    const navigate = useNavigate();

    const [opene, setOpene] = React.useState(false);
    const [notif_title, setNotifTitle] = React.useState(false);
    const [popup_notif, setPopupNotif] = React.useState(false);
    const [is_loading, setIsLoading] = React.useState(false);
    const [has_notifs, setHasNotifs] = React.useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await API.get(`list_mynotifs?userid=${user.id}`)
                .then(res => {
                    if (res.data.status) {
                        const data = res.data.notifs;
                        if (data.length > 0) {
                            setNotifications(data);
                            setHasNotifs(true);
                        }
                    }
                }).finally(res => {
                    setIsLoading(false);
                })
        }
        fetchData(user);
    }, [user, setHasNotifs, setIsLoading]);


    const goToAddERappelventHandler = () => (e) => {
        setOpene(true);
        setNotifTitle('');
        setPopupNotif([]);
    }
    const handleClose = () => {
        setOpene(false);
    };
    if (!token) {
        return <Login/>
    }
    return (
        <>
            <div className="home-wrapper">
                <TopAppBar/>

                <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                    <Grid container spacing={3}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                        <Grid item xs style={{padding: 0}}>
                            <Itembreadcrumb style={{padding: 0}}>
                                <Button className="btnback"
                                        onClick={() => navigate(-1)}
                                        startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                            </Itembreadcrumb>
                        </Grid>
                        <Grid item xs={6} style={{padding: 0}}>
                            <Itembreadcrumb style={{padding: 0}}>
                                <Typography gutterBottom variant="h6" component="div"
                                            style={{color: '#f8db94'}}>Rappels</Typography>
                            </Itembreadcrumb>
                        </Grid>
                        <Grid item xs style={{padding: 0}}>

                        </Grid>
                    </Grid>


                </Stack>
                <div style={{marginTop: 0}}>
                    <Card style={{paddingTop: 20,backgroundColor: 'transparent'}} >

                        <CardContent>

                            {!is_loading && has_notifs
                                ? <><Button
                                    onClick={goToAddERappelventHandler()}
                                    style={{marginRight: 10, fontSize: '10px', top: -10, float: 'right'}}
                                    variant="contained"
                                    endIcon={<AddIcon/>} size="small" color="warning"> Ajouter Rappel
                                </Button><Button
                                    onClick={goToAddERappelventHandler()}
                                    style={{marginRight: 10, fontSize: '10px', top: -10, float: 'right'}}
                                    variant="contained"
                                    endIcon={<AddIcon/>} size="small" color="info"> Rappel Automatique
                                </Button><NotifList Notifs={notifications} HasNotifs={has_notifs}/></>
                                : <>
                                {myplants
                                    ?
                                    <>
                                    { myplants.length > 0
                                        ?  <>
                                         <Button
                                            onClick={goToAddERappelventHandler()}
                                            style={{marginRight: 10, fontSize: '10px', top: -10, float: 'right'}}
                                            variant="contained"
                                            endIcon={<AddIcon/>} size="small" color="warning"> Ajouter Rappel
                                        </Button>
                                        <Button
                                            onClick={goToAddERappelventHandler()}
                                            style={{marginRight: 10, fontSize: '10px', top: -10, float: 'right'}}
                                            variant="contained"
                                            endIcon={<AddIcon/>} size="small" color="info"> Rappel Automatique
                                        </Button>
                                        </>:''
                                    }
                                    </>
                                    :''
                                }:''
                                <LoaderNotif/>
                                </>
                            }
                        </CardContent>
                    </Card>
                </div>


            </div>
            <div style={dialogStyle}>
                <Dialog aria-labelledby="customized-dialog-title"
                        id='custommodal-dialog' open={opene}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose} onValidate={''}>
                        Nouveau Rappel
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography style={{
                            fontSize: 16,
                            textAlign: 'center',
                            padding: '0px',
                            fontWeight: 700
                        }}>{notif_title}</Typography>
                        <AddERappel Notif={popup_notif}/>
                    </DialogContent>
                </Dialog>

            </div>
        </>
    );

}

export default Notification;
