import React  from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import moment from "moment";
import {Masonry} from "@mui/lab";
import useToken from "../App/useToken";
import Login from "../Login/Login";
import {useNavigate} from "react-router-dom";

import imgremplacement from "../../assets/imgremplacement.svg";


function PlantList({dataParentToChild}) {

     const myplants = dataParentToChild;
    const navigate = useNavigate();
    const {token} = useToken();


    if (!token) {
        return <Login/>
    }
    const goToFicheEventHandler = (plant) => (e) => {
        navigate(`/plant-details/${plant.id}`);
    }
    const loadImg = (url) =>{
        let  imageC='';
        if(url) {
            if(url.toLowerCase().includes("http://")   || url.toLowerCase().includes("https://") ){
                imageC=url
            }else{
                imageC=`https://demo.sogreen.tn${url}`
            }
        }
        return imageC;
    };
    return (
        <div className="container main-content" style={{marginTop: 20}}>

                <Masonry columns={2} spacing={1}>

                {myplants ?
                    myplants.map((plant, index) => {

                       return (
                                <Card key={index} sx={{maxWidth: '50%', height: "auto"}}  style={{  backgroundColor:"transparent" }}>
                                    <CardMedia onClick={goToFicheEventHandler(plant)}
                                               component="img"
                                               image={loadImg(plant.thumb)} alt={plant.name}
                                               onError={e => {
                                                   e.target.src = imgremplacement;
                                               }}
                                               height="100"
                                    />
                                    <CardContent sx={{lineHeight: "18px", padding: "5px"}}
                                        style={{lineHeight: "18px", padding: "5px", backgroundColor:"#607556", borderRadius: '0 0 10px 10px'   }}
                                        onClick={goToFicheEventHandler(plant)} >
                                        <Typography gutterBottom variant="p" component="div" color="rgb(234, 222, 170)">{plant.name}</Typography>
                                        {plant.notifs  ? <>
                                         {plant.notifs.length ?
                                            plant.notifs.map((notif, indexD) => {
                                            var dateretard= moment(notif.date_notif).startOf('seconds').fromNow();
                                            var position = dateretard.search("dans");
                                            if(position === -1){
                                                dateretard  = notif.ActionRequired+ ': '+  dateretard.replace('il y a ','')+" de retard";
                                            }else{
                                                dateretard  =notif.ActionRequired+ ': '+  dateretard ;
                                             }
                                              //  console.log( dateretard);
                                                return (
                                            <Typography  key={indexD} gutterBottom variant="p" component="div" style={{fontSize:'11px',color:'red' }}>{dateretard}</Typography>
                                                )
                                           } )  :''
                                         }  </>:''
                                         }
                                    </CardContent>

                                </Card>
                        )

                    })
                    :''
                }
                </Masonry>
        </div>
    );
}

export default PlantList;
