import {styled} from "@mui/material/styles";
import {Paper} from "@mui/material";
import {grey} from "@mui/material/colors";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";


export const Root = styled('div')(({theme}) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

export const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
    display: 'block',
}));

export const Puller = styled(Box)(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

export const useStyles = makeStyles(() => {
    return {
        container: {
            padding: '2em'
        },
        field: {
            display: 'block',
            marginTop: '2em',
            marginBottom: '1em'
        },
        divider: {
            marginTop: '1em',
            marginBottom: '1em'
        }
    }
})


export const Itembreadcrumb = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    boxShadow:'none',
    ...theme.typography.body2,
    padding: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));
