import {useState} from 'react';

export default function useIsFirst() {
    const getIsFirst = () => {
        const IsFirstString = localStorage.getItem('is_first');
        return JSON.parse(IsFirstString)
    };

    const [ is_first, setIsFirst] = useState(getIsFirst());

    const saveIsFirst = userIsFirst => {
        localStorage.setItem('is_first', userIsFirst);
        setIsFirst(userIsFirst);
    };

    return {
        setIsFirst: saveIsFirst,
        is_first
    }
}
