import {useState} from 'react';

export default function useDemandes() {
    const getDemandes = () => {
        const demandesString = localStorage.getItem('demandes');
        return JSON.parse(demandesString)
    };

    const [demandes, setDemandes] = useState(getDemandes());

    const saveDemandes = Demandes => {
        localStorage.setItem('demandes', JSON.stringify(Demandes));
        setDemandes(Demandes);
    };

    return {
        setDemandes: saveDemandes,
        demandes
    }
}
