import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../../constants/styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import {Button, Divider, FormControl, Stack, TextareaAutosize, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import TopAppBar from "../../../partials/TopAppBar";
import MyPlants from "../MyPlants/MyPlants";
import useMyPlants from "../../App/useMyPlants";
import {useNavigate} from "react-router";
import API from '../../../api';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './CalEntretien.css';

const useStyles = makeStyles(() => {
    return {
        container: {
            padding: '2em'
        },
        field: {
            display: 'block',
            marginTop: '1em',
            marginBottom: '1em'
        },
        divider: {
            marginTop: '1em',
            marginBottom: '1em'
        }
    }
})

function CalEntretien({plantId}) {

    const classes = useStyles();
    const [has_plants, setHasPlants] = useState(false);
    const {myplants, setMyPlants} = useMyPlants();
    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        name: {
            value: '',
            defaultValue: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        description: {
            value: '',
            defaultValue: 'description',
            error: false,
            errorMessage: 'You must enter description'
        },
        image: {
            value: '',
            defaultValue: 'image',
            error: false,
            errorMessage: 'You must enter image'
        }
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}

        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if (currentValue === '') {
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }

        }

        setFormValues(newFormValues);
        API.post(`add_entretien`, {entretien: newFormValues, plant_id: (plantId) ? plantId : null}).then(res => {
            if (res.data.status) {
                setMyPlants(res.data.data);
                setHasPlants(true);
                return <MyPlants/>
            } else {
                setHasPlants(false);
            }

        })
    }

    if (myplants && has_plants) {
        return <MyPlants/>
    }

    return (
        <div className="form-wrapper">
            <TopAppBar/>

            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>Add your plant</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}} >

                    </Grid>
                </Grid>


            </Stack>
            <Divider component="h3" variant="inset"/>
            <div   style={{width: '100%',backgroundColor: 'rgb(225 231 202)',  padding: '10px' }}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
                autoComplete="off" onSubmit={handleSubmit}
                noValidate
            >
                <div>
                    <TextField className="inputAddText" required
                               name="name"
                               size="small"
                               value={formValues.name.value ?? ''}
                               label={formValues.name.defaultValue}
                               onChange={handleChange}
                               error={formValues.name.error}
                               helperText={formValues.name.error && formValues.name.errorMessage}/>


                    <TextareaAutosize className="inputTextAria"
                                      aria-label="maximum height"
                                      placeholder="Maximum 4 rows"
                                      minRows={6}
                                      maxRows={6}

                                      name="description"
                                      value={formValues.description.value ?? ''}
                                      label={formValues.description.defaultValue}
                                      onChange={handleChange}/>

                    <input accept="image/*" style={{margin: 40}}
                           type="file"
                           name="image"
                           onChange={handleChange}
                           id="icon-button-file"/>

                </div>
                <FormControl className={classes.field}>
                    <Button variant="contained" color="success" type="submit">Submit</Button>
                </FormControl>
            </Box>
            </div>
        </div>
    )
}

export default CalEntretien;
