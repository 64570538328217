import React, {useEffect, useState} from 'react';
import {Button, Divider,   Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../constants/styled-components";
import useToken from "../App/useToken";
import Login from "../Login/Login";
import TopAppBar from "../../partials/TopAppBar";
import API from "../../api";
import useShopPlants from "../App/useShopPlants";
import PlantList from "./PlantList";
import Loader from "../../partials/Loader";
import {useNavigate} from "react-router";
import './Marketplace.css';

function useDidMount() {
    const [didMount, setDidMount] = useState(false)
    useEffect(() => { setDidMount(true) }, [])

    return didMount
}
function Marketplace() {
    const {token} = useToken();
    const [is_loading, setIsLoading] = useState(true);
    const {shopplants, setShopPlants} = useShopPlants();
    const didMount = useDidMount()

    let navigate = useNavigate();

    useEffect(() => {
        if (didMount) {
            const fetchData = async ()  => {
                await API.get(`list_marketplante`)
                    .then(res => {
                        const data = res.data;
                        if (data.plants) {
                            if (data.plants.length > 0) {
                                setShopPlants(data.plants);
                            }
                        }
                    })
                    .finally(res => {
                        setIsLoading(false);
                    });
            }
            fetchData();
        }
    }, [didMount]);

    if (!token) {
        return <Login/>
    }
    return (
        <div className="home-wrapper">
            <TopAppBar/>

            <Stack direction="row" className="breadcrumbnav" style={{padding:0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width:'100%', margin:0,padding:0}}>
                    <Grid item xs style={{padding:0}}>
                        <Itembreadcrumb style={{padding:0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon   style={{width:'50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}} />}   > </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding:0}}>
                        <Itembreadcrumb style={{padding:0}}>
                            <Typography gutterBottom variant="h6" component="div" style={{color: '#f8db94'}}>Market des plantes</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding:0}}>

                    </Grid>
                </Grid>



            </Stack>
            <div style={{marginTop: 20}}>
                <div id="plants_list" style={{  overflowY: 'scroll', maxHeight: '410px'}}>
                    <Divider component="h3" variant="inset"/>
                </div>
                {!is_loading ?
                    <PlantList shopplants={shopplants}/>
                    : <Loader/>
                }
            </div>


        </div>
    );

}

export default Marketplace;
