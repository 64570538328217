import React, {  useState} from 'react';
import Box from '@mui/material/Box';
import {Button, Divider, FormControl, Stack, TextField} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { makeStyles } from "@mui/styles";
import API from '../../../api';
import useUserData from "./../../App/useUserData";
import TopAppBar from "../../../partials/TopAppBar";
import Details from "../Details/Details";
import useGardenData from "../../App/useGardenData";
import {useNavigate} from "react-router";
import './AddGarden.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../../constants/styled-components";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles(() => {
    return {
        container:{
            padding:'2em'
        },
        field:{
            display:'block',
            marginTop:'1em',
            marginBottom:'1em'
        },
        divider:{
            marginTop:'1em',
            marginBottom:'1em'
        }
    }
})


function AddGarden() {

    const classes = useStyles();
    const [has_garden, setHasGarden] = useState(false);
    const {setGarden} = useGardenData();
    const {setUser} = useUserData();
    const userdataString = localStorage.getItem('userdata');
    const user = JSON.parse(userdataString);

    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        name:{
            value:'',
            defaultValue:'nom',
            error:false,
            errorMessage:'You must enter a name'
        },
        superficie:{
            value:0,
            defaultValue:'Superficie',
            error:false,
            errorMessage:'You must enter an superficie'
        },
        orientation:{
            value:'',
            defaultValue:'Orientation',
            error:false,
            errorMessage:'You must enter  orientation'
        },
        type_sol:{
            value:'',
            defaultValue:'Type sol',
            error:false,
            errorMessage:'You must choose your type sol'
        },
        style:{
            value:'',
            defaultValue:'Style',
            error:false,
            errorMessage:'You must choose your style'
        },
        forme:{
            value:'',
            defaultValue:'forme',
            error:false,
            errorMessage:'You must choose your forme'
        }
    })


    const handleChange = (e) => {
        const {name, value} = e.target ;
        setFormValues({
            ...formValues,
            [name]:{
                ...formValues[name],
                value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formFields = Object.keys(formValues);
        let newFormValues = {...formValues}

        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if (currentValue === '') {
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }

        }

        setFormValues(newFormValues);
        await API.post(`add_jardin`, {garden: newFormValues, user_id: (user) ? user.id : null}).then(res => {
            if (res.data.status) {
                user.garden = res.data.data;
                user.garden_id = user.garden.id;
                user.garden_name = user.garden.name;
                setGarden(user.garden);
                setUser(user);
                setHasGarden(true);
            } else {
                setHasGarden(false);
            }

        })
    }
    if(has_garden) {
        return <Details/>
    }


    return(
        <div className="form-wrapper">
            <TopAppBar/>

            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>Créer votre jardin</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}} >

                    </Grid>
                </Grid>


            </Stack>
                <Divider component="h3" variant="inset" />
            <div   style={{width: '100%',backgroundColor: 'rgb(225 231 202)',  padding: '10px' }}>

                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    autoComplete="off"  onSubmit={handleSubmit}
                    noValidate
                >
                    <div>
                        <TextField   className="inputAddText"  required
                                     name="name"
                                     size="small"
                                     value={formValues.name.value ?? ''}
                                     label={formValues.name.defaultValue }
                                     onChange={handleChange}
                                     error={formValues.name.error}
                                     helperText={formValues.name.error && formValues.name.errorMessage}   />

                        <TextField  className="inputAddText" required
                                    size="small"
                                    name="superficie"
                                    value={formValues.superficie.value ?? ''}
                                    label={formValues.superficie.defaultValue }
                                    onChange={handleChange}
                                    error={formValues.superficie.error}
                                    helperText={formValues.superficie.error && formValues.superficie.errorMessage} />

                        <TextField  className="inputAddText" required
                                    size="small"
                                    name="orientation"
                                    value={formValues.orientation.value ?? ''}
                                    label={formValues.orientation.defaultValue }
                                    onChange={handleChange}
                                    error={formValues.orientation.error}
                                    helperText={formValues.orientation.error && formValues.orientation.errorMessage} />

                        <TextField  className="inputAddText" required
                                    size="small"
                                    name="style"
                                    value={formValues.style.value ?? ''}
                                    label={formValues.style.defaultValue }
                                    onChange={handleChange}
                                    error={formValues.style.error}
                                    helperText={formValues.style.error && formValues.style.errorMessage} />


                        <TextField  className="inputAddText" required
                                    size="small"
                                    name="type_sol"
                                    value={formValues.type_sol.value ?? ''}
                                    label={formValues.type_sol.defaultValue }
                                    onChange={handleChange}
                                    error={formValues.type_sol.error}
                                    helperText={formValues.type_sol.error && formValues.type_sol.errorMessage} />


                        <TextField  className="inputAddText" required
                                    size="small"
                                    name="forme"
                                    value={formValues.forme.value ?? ''}
                                    label={formValues.forme.defaultValue }
                                    onChange={handleChange}
                                    error={formValues.forme.error}
                                    helperText={formValues.forme.error && formValues.forme.errorMessage} />

                    </div>
                    <FormControl className={classes.field} >
                        <Button  variant="contained" color="success"  type="submit" >Créer</Button>
                    </FormControl>
                </Box>
            </div>


        </div>
    )
}

export default AddGarden;
