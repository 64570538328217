import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function Article({title,desc,img}) {
    const Title = title;
    const Desc = desc;
    const Img = img;

    return (
        <Card sx={{ maxWidth: 200, marginTop:'6px' }} style={{backgroundColor:'#f8db94'}}>
            <CardHeader titleTypographyProps={{variant:'h6' }} style={{padding:'0', paddingTop:'6px'}}
                title={Title}
            />
            <CardContent  style={{padding:'4px 0' }}>
                <Typography variant="body2" color="text.secondary" style={{fontZise:'12px'}}>
                    {Desc}
                </Typography>
            </CardContent>
            <CardMedia
                component="img"  style={{maxHeight:'80px'}}
                image= {Img}
                alt={Title}
            />


        </Card>
    );
}
