import {useState} from 'react';

export default function useIdeas() {
    const getIdeas = () => {
        const ideasString = localStorage.getItem('ideas');
        return JSON.parse(ideasString)
    };

    const [ideas, setIdeas] = useState(getIdeas());

    const saveIdeas = Ideas => {
        localStorage.setItem('ideas', JSON.stringify(Ideas));
        setIdeas(Ideas);
    };

    return {
        setIdeas: saveIdeas,
        ideas
    }
}
