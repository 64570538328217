import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useToken from "../App/useToken";
import Login from "../Login/Login";
import {useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {Masonry} from "@mui/lab";
import imgremplacement from "../../assets/imgremplacement.svg";

function PlantList({shopplants}) {

    const myplants = shopplants;
    const navigate = useNavigate();

    const {token} = useToken();

    if (!token) {
        return <Login/>
    }
    const goToFicheEventHandler = (plant) => (e) => {
        navigate(`/shopplant-details/${plant.id}`);
    }
    const goToShopProcessEventHandler = (plant) => (e) => {
        navigate(`/Shop_process/${plant.id}`);
    }
    return (
        <div className="container main-content" style={{marginTop: 15}}>
            <Masonry columns={2} spacing={1}>
            {
                myplants.map((plant, index) => {
                    return (
                        <div  key={index} >
                            <Card sx={{  height: "auto"}}  style={{  backgroundColor:"transparent" }}
                                  onClick={goToFicheEventHandler(plant)}>
                                <CardMedia
                                    component="img"
                                    image={plant.thumb} alt={plant.name}
                                    height="100"
                                    onError={e => {
                                        e.target.src = imgremplacement;
                                    }}
                                />
                                <CardContent onClick={goToFicheEventHandler(plant)}
                                             sx={{lineHeight: "18px", padding: "5px"}}  style={{lineHeight: "18px", padding: "5px", backgroundColor:"#607556", borderRadius: '0 0 10px 10px'   }}>
                                    <Typography gutterBottom variant="p" component="div"  color="rgb(234, 222, 170)">{plant.name}</Typography>

                                </CardContent>
                            </Card>
                            <Button onClick={goToShopProcessEventHandler(plant)}  style={{marginRight: 10, fontSize: '10px', top: -10}}
                                    variant="contained" endIcon={<AddIcon/>} size="small"
                                    color="success"> Acheter a {plant.prix}DT</Button>

                        </div>
                    )

                })
            }
            </Masonry>
        </div>
    );
}

export default PlantList;
