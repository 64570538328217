import React, {useEffect, useState} from 'react';
import AnimateHeight from "react-animate-height";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardHeader from "react-bootstrap/CardHeader";
import {makeStyles} from "@mui/styles";
import {FormControl, Grid} from "@mui/joy";
import {Button, Divider, FormControlLabel, Paper, TextField} from "@mui/material";
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import Login from "../Login/Login";
import plantPot from "../../assets/fern.png";
import plantOne from "../../assets/plantOne.gif";
import plantTwo  from "../../assets/plantTwo.gif";
import TopAppBar from "../../partials/TopAppBar";
import useToken from "../App/useToken";
import useIsFirst from "../App/useIsFirst";
import useUserData from "../App/useUserData";
import './Account.css';
import API from "../../api";
import {Itembreadcrumb} from "../../constants/styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {useNavigate} from "react-router";

const useStyles = makeStyles(() => {
    return {
        container: {
            padding: '2em'
        },
        field: {
            display: 'block',
            marginTop: '1em',
            marginBottom: '1em'
        },
        divider: {
            marginTop: '40px',
            marginBottom: '1em'
        }
    }
})
const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));


async function updateUser(data) {
    return  await API.post(`update_userifos`,data).then(res => res.data.data) ;
}

function Account() {

    const {user, setUser} = useUserData();
    const {is_first, setIsFirst} = useIsFirst();
    const {token} = useToken();
    const [height, setHeight] = useState(0);
    const [is_welcom, setIsWelcom] = useState(false);
    const [is_step_a, setIsStepA] = useState(false);
    const [is_step_ar, setIsStepAr] = useState(false);
    const [is_step_b, setIsStepB] = useState(false);
    const [is_step_br, setIsStepBr] = useState(false);
    const [is_step_c, setIsStepC] = useState(false);
    const [is_step_cr, setIsStepCr] = useState(false);
    const [isInitialRender, setIsInitialRender] = useState(is_first);
  //  const [tempe_hiver, setTempeHiver] = useState(0);
    const [tempe_ete, setTempeEte] = useState(0);
    const [atmospher, setAtmospher] = useState('');
    const [nearest_watherstation, setnNearestWatherstation] = useState('');

    let navigate = useNavigate();
    const classes = useStyles();

   useEffect(() => {
        if(isInitialRender){
            setIsWelcom(true);
            setHeight(height === 0 ? '250' : 0);
            setIsInitialRender(false);
        }
   },[isInitialRender, height,setIsInitialRender]);
    if (!token) {
        return <Login/>
    }

    const handleInputAChanged= (e) => {
        setTempeEte(e.target.value);
    }
    const handleInputBChanged= (e) => {
        setAtmospher(e.target.value);
    }

    const handleInputCChanged= (e) => {
        setnNearestWatherstation(e.target.value);
    }

    const handleWelcomClose = (e) => {
        setIsWelcom(false);
        setIsStepA(true);
        setIsStepAr(false);
        setIsStepB(false);
        setIsStepBr(false);
        setIsStepC(false);
        setIsStepCr(false);

    };

    const handleStepAClose = async (e) => {
        e.preventDefault();
        setIsWelcom(false);
        setIsStepA(false);
        setIsStepAr(true);
        setIsStepB(false);
        setIsStepBr(false);
        setIsStepC(false);
        setIsStepCr(false);
         await  updateUser({id: user.id, tempe_ete:tempe_ete}).then(res =>
            {
                if(res)
                    setUser(res);
            }
        );

    };

    const handleStepARClose = (e) => {
        setIsWelcom(false);
        setIsStepA(false);
        setIsStepAr(false);
        setIsStepB(true);
        setIsStepBr(false);
        setIsStepC(false);
        setIsStepCr(false);
    };

    const handleStepBClose = async (e) => {
        e.preventDefault();
        setIsWelcom(false);
        setIsStepA(false);
        setIsStepAr(false);
        setIsStepB(false);
        setIsStepBr(true);
        setIsStepC(false);
        setIsStepCr(false);
        await updateUser({id: user.id, atmospher:atmospher}).then(res =>
            {
                if(res)
                    setUser(res);
            }
        );
    };

    const handleStepBRClose = (e) => {
        setIsWelcom(false);
        setIsStepA(false);
        setIsStepAr(false);
        setIsStepB(false);
        setIsStepBr(false);
        setIsStepC(true);
        setIsStepCr(false);
    };

    const handleStepCClose = async (e) => {
        e.preventDefault();
        setIsWelcom(false);
        setIsStepA(false);
        setIsStepAr(false);
        setIsStepB(false);
        setIsStepBr(false);
        setIsStepC(false);
        setIsStepCr(true);
        await updateUser({id: user.id, nearest_watherstation:nearest_watherstation}).then(res =>
            {
                if(res)
                    setUser(res);
            }
        );
    };

    const handleStepCRClose = (e) => {
        setIsWelcom(false);
        setIsStepA(false);
        setIsStepAr(false);
        setIsStepB(false);
        setIsStepBr(false);
        setIsStepC(false);
        setIsStepCr(false);
        setIsFirst(false);
    };



    return (
        <div className="home-wrapper">
            { !(is_welcom || is_first ) ?
                <TopAppBar  />:''
            }
            <Stack direction="row" className="breadcrumbnav" style={{padding:0}}   hidden={(is_welcom || is_first ) }>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width:'100%', margin:0,padding:0}}>
                    <Grid item xs style={{padding:0}}>
                        <Itembreadcrumb style={{padding:0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon   style={{width:'50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}} />}   > </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding:0}}>
                        <Itembreadcrumb style={{padding:0}}>
                            <Typography gutterBottom variant="h6" component="div" style={{color: '#f8db94'}}>Mon Profil</Typography>
                       </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding:0}}>

                    </Grid>
                </Grid>



            </Stack>
            <div  style={{marginTop: 0   }}>
            {user
                ?<><Card style={{paddingTop: 20 }}  hidden={is_welcom || is_step_a || is_step_b || is_step_c || is_step_ar || is_step_br || is_step_cr }>
                    <CardHeader>

                        <div style={{ width: "100%", height: 'auto' }}>
                            <Stack direction="row" spacing={2} style={{ width: "100%" }}>
                                <StyledBadge
                                    style={{ marginRight: "auto", marginLeft: "auto" }}
                                    overlap="circular"
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    variant="dot"
                                >
                                    <Avatar
                                        alt=""
                                        src="/static/images/avatar/1.jpg"
                                        style={{ marginRight: "auto", marginLeft: "auto" }}
                                    />
                                </StyledBadge>
                            </Stack>

                            <h3
                                style={{
                                    textAlign: "center",
                                    width: "50%",
                                    lineHeight: "30px",
                                    marginRight: "auto",
                                    marginLeft: "auto"
                                }}
                            >
                                {user?.first_name} {user?.last_name} </h3>
                            <Stack spacing={2}>
                                <Item> <b>Email :</b> {user?.email}</Item>
                                <Item> <b>Moyenne des températures en été :</b> {user?.ete_temp}°</Item>
                                <Item> <b>Votre jardin est :</b> {user?.atmospher}</Item>
                                <Item> <b>Station météo la plus proche :</b> {user?.nearestwatherstation}</Item>


                            </Stack>
                        </div>
                    </CardHeader>
                    <CardContent>

                    </CardContent>
                </Card>

                <AnimateHeight style={{paddingTop: 100 }}   duration={500} height={'auto'} >
                    <div className="container" hidden={!is_welcom}>
                        <div className="cookiesContent" id="cookiesPopupW">
                            <img src={plantPot} alt={''}/>
                            <h4>Vous êtes connecté(e) </h4>
                            <p>Bienvenue sur SoGreen</p>
                            <button className="accept" onClick={handleWelcomClose}>Ok</button>
                        </div>
                    </div>
                    <div className="container" hidden={!is_step_a}>
                        <div className="cookiesContent" id="cookiesPopupA">
                            <img src={plantPot} alt={''}/>
                            <span>Personalisation du calandrier</span>
                            <h4>Quelle est la moyenne des températures en été, dans votre région ?</h4>
                            <Divider component="div"/>
                            <Box component="form"
                                 autoComplete="off" onSubmit={handleStepAClose}
                                 noValidate
                            >
                                <TextField id='tempe_ete' onChange={handleInputAChanged}
                                           className="inputAddText"
                                           size="small" style={{marginTop: 20,marginBottom: 20}}/>
                                <Button className="accept" type="submit" >Repondre</Button>
                            </Box>


                        </div>
                    </div>
                    <div className="container" hidden={!is_step_ar}>
                        <div className="cookiesContent" id="responcePopupaR">
                            <img src={plantOne} alt={''}/>
                            <h4> Vous êtes dans un climat semi-aride {tempe_ete}°</h4>
                            <p>Jardinage toute l'année avec des étés extremement chauds et des hivers trés doux</p>
                            <div className={classes.divider}>
                                <Divider component="div"/>
                            </div>
                            <button className="accept" onClick={handleStepARClose}>Suivant</button>

                        </div>
                    </div>
                    <div className="container" hidden={!is_step_b}>
                        <div className="cookiesContent" id="cookiesPopupB">
                            <img src={plantPot} alt={''}/>

                            <Divider component="div"/>


                            <Box component="form"
                                 autoComplete="off" onSubmit={handleStepBClose}
                                 noValidate
                            >
                                <Typography id="segmented-controls-example" fontWeight="lg" fontSize="sm">
                                    Votre jardin est ?
                                </Typography>
                                <FormControl style={{marginTop: 20,marginBottom: 20}}>
                                    <RadioGroup  onChange={handleInputBChanged}
                                        row
                                        value={atmospher}
                                        aria-labelledby="row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="Ensoleillé" control={<Radio />} label="Ensoleillé" />
                                        <FormControlLabel value="Ombragé" control={<Radio />} label="Ombragé" />
                                        <FormControlLabel value="Mi-ombragé" control={<Radio />} label="Mi-ombragé" />
                                        <FormControlLabel
                                            value="disabled"
                                            disabled
                                            control={<Radio />}
                                            label="other"
                                        />
                                    </RadioGroup>
                                </FormControl>


                                <Button className="accept" type="submit" >repondre</Button>
                            </Box>

                        </div>
                    </div>
                    <div className="container" hidden={!is_step_br}>
                        <div className="cookiesContent" id="responcePopupbR">
                            <img src={plantTwo} alt={''}/>
                            <h4> Votre jardin est: {atmospher}</h4>
                            <div className={classes.divider}>
                                <Divider component="div"/>
                            </div>
                            <button className="accept" onClick={handleStepBRClose}>ok</button>

                        </div>
                    </div>
                    <div className="container" hidden={!is_step_c}>
                        <div className="cookiesContent" id="cookiesPopupC">
                            <img src={plantPot} alt={''}/>

                            <Divider component="div"/>


                            <Box component="form"
                                 autoComplete="off" onSubmit={handleStepCClose}
                                 noValidate
                            >
                                <Typography id="segmented-controls-example" fontWeight="lg" fontSize="sm">
                                    localisation (station météo la plus proche) ?
                                </Typography>
                                <FormControl style={{marginTop: 20,marginBottom: 20}}>
                                    <TextField id='nearest_watherstation' onChange={handleInputCChanged}
                                               className="inputAddText"
                                               size="small" style={{marginTop: 20,marginBottom: 20}}/>
                                </FormControl>


                                <Button className="accept" type="submit" >repondre</Button>
                            </Box>

                        </div>
                    </div>
                    <div className="container" hidden={!is_step_cr}>
                        <div className="cookiesContent" id="responcePopupcR">
                            <img src={plantTwo} alt={''}/>
                            <h4>La station météo la plus proche est: {nearest_watherstation}</h4>
                            <div className={classes.divider}>
                                <Divider component="div"/>
                            </div>
                            <button className="accept" onClick={handleStepCRClose}>ok</button>

                        </div>
                    </div>
                </AnimateHeight>
                </>
                : ""
            }
            </div>


        </div>
    );

}

export default Account;
