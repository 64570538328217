import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import useToken from "../App/useToken";
import Login from "../Login/Login";
import {useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import API from "../../api";
import CalEntretien from "../MyGarden/CalEntretien/CalEntretien";
import useGardenData from "../App/useGardenData";
import useMyPlants from "../App/useMyPlants";
import AnimateHeight from "react-animate-height";
import {useEffect, useState} from "react";
import {Masonry} from "@mui/lab";
import imgremplacement from "../../assets/imgremplacement.svg";

function PlantList({dataParentToChild}) {

    const libplants = dataParentToChild;
    const {garden} = useGardenData();
    const {myplants, setMyPlants} = useMyPlants();
    const [has_plants, setHasPlants] = useState(false);
    const [no_garden, setNoGarden] = useState(false);
    const navigate = useNavigate();

    const {token} = useToken();

    useEffect(() => {
        if(no_garden){
            handleNoGardenClose();
        }
    },[]);
    if (!token) {
        return <Login/>
    }
    const goToFicheEventHandler = (plant) => (e) => {
        navigate(`/libplant-details/${plant.id}`);
    }

    const AddToGardenEventHandler = (plant) => async (e) => {

        await API.post(`add_plant_to_garden`, {plant: plant.id, garden_id: garden.id, type: 'lib'}).then(res => {
            if (res.data.status) {

                setMyPlants(res.data.plants);
                setHasPlants(true);
                return <CalEntretien plantId={plant.id}/>
            } else {

                setNoGarden(true);
                setHasPlants(false);
            }

        });

    }

    const handleNoGardenClose = (e) => {

        navigate('/add-garden');
    };
    const isAddedToGarden = (plant) => {
        if (myplants) {

            const isFound = myplants.some(element => {
                return element.id === plant.id;
            });

            if (isFound) {
              //  console.log('✅ array contains object with id = 1');
                return true;
            }
        }
        return false;
    }

    const loadImg = (url) =>{
        let  imageC='';
        if(url) {
            if(url.toLowerCase().includes("http://")   || url.toLowerCase().includes("https://") ){
                imageC=url
            }else{
                imageC=`https://demo.sogreen.tn${url}`
            }
        }
        return imageC;
    };

    return (
        <div className="container main-content" style={{marginTop: 20}}>
                <Masonry columns={2} spacing={1}>
            {
                libplants.map((plant, index) => {
                    return (
                        <div key={index} >
                            <Card sx={{  height: "auto"}}  style={{  backgroundColor:"transparent" }}>
                                <CardMedia onClick={goToFicheEventHandler(plant)}
                                           component="img"
                                           image={loadImg(plant.thumb)} alt={plant.name}
                                           height="100"
                                           onError={e => {
                                               e.target.src = imgremplacement;
                                           }}
                                />
                                <CardContent onClick={goToFicheEventHandler(plant)}  style={{ backgroundColor:"#607556", borderRadius: '0 0 10px 10px'   }}
                                             sx={{lineHeight: "18px", padding: "5px"}}>
                                    <Typography gutterBottom variant="p" component="div"  color="rgb(234, 222, 170)">{plant.name}</Typography>
                                </CardContent>

                            </Card>
                            <div hidden={isAddedToGarden(plant)}><Button onClick={AddToGardenEventHandler(plant)}
                                                                         style={{
                                                                             marginRight: 10,
                                                                             fontSize: '10px',
                                                                             top: -10,
                                                                             float: 'right'
                                                                         }} variant="contained"
                                                                         endIcon={<AddIcon/>} size="small"
                                                                         color="success"> Ajouter </Button>
                            </div>
                        </div>
                    )

                })
            }

                </Masonry>


            <AnimateHeight  hidden={!no_garden}
                            duration={ 500 }
                           height={ 'auto' }
            >
                <div className="container" >
                    <div className="cookiesContent" id="cookiesPopupW">
                        <p>Veillez Créer une jardin</p>
                        <button className="accept" onClick={handleNoGardenClose}>Créer Jardin</button>
                    </div>
                </div>

            </AnimateHeight>
        </div>


    );
}

export default PlantList;
