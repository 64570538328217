import {useState} from 'react';

export default function useMyNotes() {
    const getMyNotes = () => {
        const mynotesString = localStorage.getItem('my_notes');
        return JSON.parse(mynotesString)
    };

    const [mynotes, setMyNotes] = useState(getMyNotes());

    const saveMyNotes = userMyNotes => {
        localStorage.setItem('my_notes', JSON.stringify(userMyNotes));
        setMyNotes(userMyNotes );
    };

    return {
        setMyNotes: saveMyNotes,
        mynotes
    }
}
