import React, {useEffect, useState} from 'react';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {  Stack } from "@mui/material";
import Button from "@mui/material/Button";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Item, Itembreadcrumb } from "../../../constants/styled-components";
import {planteCatNames} from "../../../constants/list-enum";
import {Grid} from "@mui/joy";
import {useNavigate, useParams} from "react-router";
import API from "../../../api";
import useToken from "../../App/useToken";
import Login from "../../Login/Login";
import TopAppBar from "../../../partials/TopAppBar";
import CalEntretien from "../../MyGarden/CalEntretien/CalEntretien";
import useGardenData from "../../App/useGardenData";
import useMyPlants from "../../App/useMyPlants";
import Loader from "../../../partials/Loader";
import './LibPlantDetails.css';
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import imgremplacement from "../../../assets/imgremplacement.svg";
import addplante from "../../../assets/addplante.png";

function LibPlantDetails() {
    const {id} = useParams();
    const {token} = useToken();
    const [plant, setPlant] = useState([]);
    const {myplants, setMyPlants} = useMyPlants();
    const [has_plants, setHasPlants] = useState(false);
    const [no_garden, setNoGarden] = useState(false);
    const [is_loading, setIsLoading] = useState(true);
    const {garden} = useGardenData();
    const [value, setValue] = React.useState('1');

    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                await   API.get(`get_plant?id=${id}`)
                    .then(res => {
                        if (res.data) {
                            setPlant(res.data);
                        }
                    }).finally(res => {
                        setIsLoading(false);
                    })
            }
        }
        fetchData();
    }, [id]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    if (!token) {
        return <Login/>
    }

    const AddToGardenEventHandler = (plant) => async (e) => {

        await API.post(`add_plant_to_garden`, {plant: plant.id, garden_id: garden.id, type: 'lib'}).then(res => {
            if (res.data.status) {

                setMyPlants(res.data.plants);
                setHasPlants(true);
                return <CalEntretien plantId={plant.id}/>
            } else {

                setNoGarden(true);
                setHasPlants(false);
            }

        });

    }
    const isAddedToGarden = (plant) => {
        if (myplants) {

            const isFound = myplants.some(element => {
                return element.id === plant.id;
            });

            if (isFound) {
               // console.log('✅ array contains object with id = 1');
                return true;
            }
        }
        return false;
    }
    return (
        <div className="home-wrapper">
            <TopAppBar/>
            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>{plant.name}</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding:0}} hidden={isAddedToGarden(plant)}>
                            <Button className="addback"  style={{ backgroundColor: '#607556' }}
                                                  variant="contained" onClick={AddToGardenEventHandler(plant)} >

                                <img src={addplante}  style={{width:'48px', height: '30px', margin: '0 auto'}} alt="Ajouter plante"/>
                            </Button>
                        </Itembreadcrumb>
                    </Grid>
                </Grid>


            </Stack>
            {!is_loading
                ? <><Card>
                    <CardMedia
                        component="img"
                        image={plant.thumb} alt={plant.name}
                        height="100"
                        onError={e => {
                            e.target.src = imgremplacement;
                        }}
                    />
                    <CardContent style={{backgroundColor:'#f6fff1'}}>

                        <Box sx={{width: '100%', typography: 'body1'}}>
                            <TabContext value={value}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="A propos" value="1"/>
                                        <Tab label="Soin" value="2"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Stack spacing={2}>
                                        <Item> <b>Categorie :</b> {planteCatNames[plant.category_id]}</Item>
                                        <Item> <b>Description :</b> {plant.description}</Item>
                                        <div hidden={isAddedToGarden(plant)}>
                                            <Button onClick={AddToGardenEventHandler(plant)}
                                                    style={{
                                                        fontSize: '14px',
                                                        top: 30,
                                                    }} variant="contained" size="medium"
                                                    color="success"> Ajouter à mon jardin </Button>
                                        </div>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value="2"> </TabPanel>
                            </TabContext>
                        </Box>

                    </CardContent>

                </Card>
                </> : <Loader/>
            }


        </div>
    );

}

export default LibPlantDetails;
