import React from 'react';
import '../../assets/css/App.css';
import Login from '../Login/Login';
import Home from '../Home/Home';
import useToken from "./useToken";
import useUserData from "./useUserData";



function App() {

    const {token} = useToken();
    const {setUser} = useUserData();


    if (!token) {
        return <Login/>
    }else{
        if(setUser){
            return (<Home/>);
        }
    }

}

export default App;
