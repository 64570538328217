import React, {useEffect, useState} from 'react';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {  FormControl, Stack, TextareaAutosize} from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {InsertComment} from "@mui/icons-material";
import {Grid} from "@mui/joy";
import { Itembreadcrumb, Item, StyledBox, Puller, useStyles} from "../../../constants/styled-components";
import {planteCatNames} from "../../../constants/list-enum";
import API from "../../../api";
import {useNavigate, useParams} from "react-router";
import Loader from "../../../partials/Loader";
import useToken from "../../App/useToken";
import Login from "../../Login/Login";
import TopAppBar from "../../../partials/TopAppBar";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import PropTypes from 'prop-types';
import {Global} from '@emotion/react';
import useUserData from "../../App/useUserData";
import ListPlanteNote from "../../DemandePaysagist/ListPlanteNote";
import useMyNotes from "../../App/useMyNotes";
import './PlantDetails.css';
import imgremplacement from "../../../assets/imgremplacement.svg";
import { useHistory } from "react-router-dom";
const drawerBleeding = 56;





function PlantDetails(props, {idplant}) {
    const {id} = useParams() ?? idplant;
    const classes = useStyles();
    const {token} = useToken();
    const {user} = useUserData();
    const [plant, setPlant] = useState([]);
    const {mynotes, setMyNotes} = useMyNotes();
    const [is_loading, setIsLoading] = useState(true);
    const [value, setValue] = React.useState('1');
    const [open, setOpen] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [description, setDescription] = useState("");
    const [formValues, setFormValues] = useState({
        description: {
            value: '',
            defaultValue: 'description',
            error: false,
            errorMessage: 'You must enter description'
        }
    })
    const navigate = useNavigate();
    const {window} = props;
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
// This function will be triggered when the file field change
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setDisabled(true);
            setSelectedImage(e.target.files[0]);
        }
    };
// This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
        setSelectedImage();
        setDisabled(false);
    };
    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                await API.get(`get_myplant?id=${id}&usrid=${user.id}`)
                    .then(res => {
                        if (res.data) {
                            setPlant(res.data);
                            if (res.data.notes)
                                setMyNotes(res.data.notes);
                        }
                    }).finally(res => {
                        setIsLoading(false);
                    });
            }
        }
        fetchData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("note", description);
        formData.append("user_id", user.id);
        formData.append("plante_id", id);
        formData.append("photo", selectedImage);

         await API.post('add_note', formData, {
            headers: {
                "content-type": "multipart/form-data"
            },
        }).then(res => {

            if (res.data) {
                if (res.data.status) {
                    const fetchData = async () => {
                        if (id) {
                            await API.get(`get_myplant?id=${id}&usrid=${user.id}`)
                                .then(res => {
                                    if (res.data) {
                                        setPlant(res.data);
                                        if (res.data.notes)
                                            setMyNotes(res.data.notes);
                                    }
                                }).finally(res => {
                                    setIsLoading(false);
                                });
                        }
                    }
                    fetchData();
                    setOpen(false)
                }
            }
        });



    }
    const refreshPage = () => {

        navigate(`/plant-details/${id}`);
    }
    const loadImg = (url) =>{
        let  imageC='';
        if(url) {
            if(url.toLowerCase().includes("http://")   || url.toLowerCase().includes("https://") ){
                imageC=url
            }else{
                imageC=`https://demo.sogreen.tn${url}`
            }
        }
        return imageC;
    };
    if (!token) {
        return <Login/>
    }
    return (
        <div className="home-wrapper">
            <TopAppBar/>

            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>{plant.name}</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}}>

                    </Grid>
                </Grid>


            </Stack>


            {!is_loading && plant
                ?
                <><Card>
                    <CardMedia
                        component="img"
                        image={loadImg(plant.thumb)} alt={plant.name}
                        height="100"
                        onError={e => {
                            e.target.src = imgremplacement;
                        }}
                    />
                    <CardContent  style={{backgroundColor:'#f6fff1'}}>
                        <Box sx={{width: '100%', typography: 'body1'}}  >
                            <TabContext value={value} >
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={handleChange} aria-label="">
                                        <Tab label="A propos" value="1"/>
                                        <Tab label="Soin" value="2"/>
                                        <Tab label="Santé" value="3"/>
                                        <Tab label="Notes" value="4"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1"  >
                                    <Stack spacing={2}>
                                        <Item> <b>Categorie :</b> {planteCatNames[plant.category_id]}</Item>
                                        <Item> <b>Description :</b> {plant.description}</Item>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value="2"> </TabPanel>
                                <TabPanel value="3"> </TabPanel>
                                <TabPanel value="4"  style={{padding:0}}>

                                        {/*<CssBaseline/>*/}
                                        <Global
                                            styles={{
                                                '.MuiDrawer-root > .MuiPaper-root': {
                                                    height: `calc(50% - ${drawerBleeding}px)`,
                                                    overflow: 'visible',
                                                    margin: '0 auto',
                                                    padding: '0',
                                                    maxWidth: '480px',
                                                },
                                            }}
                                        />

                                        {!mynotes.length
                                            ?
                                            <Box sx={{textAlign: 'center', pt: 2}}>
                                                <Button onClick={toggleDrawer(true)} style={{
                                                    fontSize: '14px',
                                                    margin: '40px ',
                                                    borderRadius: 15,
                                                    backgroundColor: '#eedbad',
                                                    textTransform: 'inherit',
                                                    color: '#101711',
                                                    padding: '5px 15px'
                                                }}>Ajouter votre premiére note</Button>
                                            </Box> : ''
                                        }
                                        {mynotes.length
                                            ?
                                                <ListPlanteNote dataNotes={mynotes}/>
                                            : ''
                                        }

                                        <SwipeableDrawer
                                            container={container}
                                            anchor="bottom"
                                            open={open}
                                            onClose={toggleDrawer(false)}
                                            onOpen={toggleDrawer(true)}
                                            swipeAreaWidth={drawerBleeding}
                                            disableSwipeToOpen={false}
                                            ModalProps={{
                                                keepMounted: true,
                                            }}
                                        >
                                            <StyledBox
                                                sx={{
                                                    position: 'absolute',
                                                    top: -drawerBleeding,
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    visibility: 'visible',
                                                    right: 0,
                                                    left: 0,
                                                }}
                                            >
                                                <Puller/>
                                                <Typography sx={{p: 2, color: 'text.secondary'}}>Ajouter une note</Typography>
                                            </StyledBox>
                                            <StyledBox
                                                sx={{
                                                    px: 2,
                                                    pb: 2,
                                                    height: '100%',
                                                    overflow: 'auto',
                                                }}
                                                component="form"
                                                autoComplete="off" onSubmit={handleSubmit}
                                                noValidate
                                            >
                                                <div style={{display: 'flex', width: '100%'}}>
                                                    <TextareaAutosize className="inputNoteTextAria"
                                                                      aria-label="maximum height"
                                                                      placeholder=""
                                                                      minRows={3}
                                                                      maxRows={4}
                                                                      onChange={(e) => setDescription(e.target.value)}
                                                                      value={description}
                                                                      name="description"
                                                                      label={formValues.description.defaultValue}
                                                    />

                                                    <div style={styles.container}>
                                                        <input className="inputimgnote"
                                                               accept="image/*"
                                                               type="file"
                                                               name="photo"
                                                               onChange={imageChange}
                                                               disabled={disabled}
                                                        />

                                                        <AddAPhotoIcon style={{
                                                            position: 'absolute',
                                                            left: 0,
                                                            top: 0,
                                                            width: 100,
                                                            height: 100
                                                        }}/>
                                                        {selectedImage && (
                                                            <div style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                                top: 0,
                                                                width: 100,
                                                                height: 100
                                                            }}>
                                                                <div style={styles.preview}>
                                                                    <IconButton onClick={removeSelectedImage}
                                                                                style={styles.delete}>
                                                                        <CloseIcon/>
                                                                    </IconButton>
                                                                    <img
                                                                        src={URL.createObjectURL(selectedImage)}
                                                                        style={styles.image}
                                                                        alt="Thumb"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>


                                                </div>
                                                <div style={{display: 'block', width: '100%'}}>
                                                    <FormControl className={classes.field}>
                                                        <Button variant="contained" color="success" type="submit"
                                                                className="btnaddph">
                                                            <InsertComment sx={{mr: 1}}/> Ajouter</Button>
                                                    </FormControl>
                                                </div>
                                            </StyledBox>
                                        </SwipeableDrawer>




                                </TabPanel>
                            </TabContext>
                        </Box>
                    </CardContent>

                </Card>

                </> : <Loader/>
            }


        </div>
    );

}

PlantDetails.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};
export default PlantDetails;

// Just some styles
const styles = {
    container: {
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        paddingTop: 5,
        width: 100,
        height: 100,
        display: 'inline-flex',
        marginTop: 0,
        background: '#f1f1f1',
        position: 'relative'
    },
    preview: {
        position: 'relative',
        maxWidth: 100,
        minHeight: 100,
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
    },
    image: {
        width: 110,
        height: 110,
        position: 'absolute',
        top: 0, right: 0
    },
    delete: {
        zIndex: 10000,
        position: 'absolute',
        top: 0, right: 0,
        width: 25, height: 25,
        cursor: "pointer",
        padding: 0,
        background: "#EEEEEE",
        color: "red",
        border: "none",
    },
};
