import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './Home.css';
import useToken from "../App/useToken";
import Login from "../Login/Login";
import TopAppBar from "../../partials/TopAppBar";
import useUserData from "../App/useUserData";
import useGardenData from "../App/useGardenData";
import useMyPlants from "../App/useMyPlants";
import API from "../../api";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Typography from "@mui/material/Typography";

function Home() {
    const {token} = useToken();
    const {user} = useUserData();
    const navigate = useNavigate();
    const { setGarden} = useGardenData();
    const {setMyPlants} = useMyPlants();
    const [is_loading, setIsLoading] = useState(true);
    const [has_garden, setHasGarden] = useState(false);
    const [gardenid, setGardenId] = useState(user?.garden_id);
    const [variant, setVariant] = React.useState(undefined);
    useEffect(() => {
        async function fetchData() {
            if (gardenid) {
                await API.get(`list_jardin?id=${gardenid}`)
                    .then(res => {
                        const data = res.data;
                        if (data.data) {
                            setHasGarden(true);
                            setGarden(data.data);
                            if (data.data.plants.length > 0) {
                                setMyPlants(data.data.plants);
                                setGardenId(data.data.id);
                            }
                        }
                    })
                    .finally(res => {
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [gardenid]);

    if (!token) {
        return <Login/>
    }


    const goToEventHandler = (route) => {
        navigate(route);
    }
    const handleMyGardenRubOpen = (event: React.MouseEvent<HTMLElement>) => {
        goToEventHandler('/my-garden');
    };
    const handleLibraryRubOpen = (event: React.MouseEvent<HTMLElement>) => {
        goToEventHandler('/library');
    };
   /* const handleMarketRubOpen = (event: React.MouseEvent<HTMLElement>) => {
        goToEventHandler('/marketplace');
    };
    onClick={handleMarketRubOpen}*/
    return (

        <div className="myhome-wrapper">
            <TopAppBar/>

            <ul className="home_btn-rub" style={{marginTop: '30%'}}>
                <li className="btn-rub" onClick={handleMyGardenRubOpen}> Mon Jardin</li>
                <li className="btn-rub" onClick={handleLibraryRubOpen}>Biblio des plantes</li>
                <li className="btn-rub"  onClick={() => {
                    setVariant('soft');
                }}>Market des plants</li>
            </ul>
            <Modal open={!!variant} onClose={() => setVariant(undefined)}>
                <ModalDialog
                    aria-labelledby="variant-modal-title"
                    aria-describedby="variant-modal-description"
                    variant={variant}
                >
                    <ModalClose />
                    <Typography id="variant-modal-description"  >
                        <br/>
                        <br/>
                        La Marketplace sera lancée prochainement
                    </Typography>
                </ModalDialog>
            </Modal>

        </div>

    );
}

export default Home;
