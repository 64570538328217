import React, {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {LogoutOutlined, MarkUnreadChatAltOutlined} from "@mui/icons-material";
import { useNavigate} from "react-router-dom";
import logo from "../assets/logo_new.svg";
import useToken from "../components/App/useToken";
import useUserData from "../components/App/useUserData";
import Login from "../components/Login/Login";
import useMyPlants from "../components/App/useMyPlants";
import useGardenData from "../components/App/useGardenData";
import useLibPlants from "../components/App/useLibPlants";
import useShopPlants from "../components/App/useShopPlants";
import useNotifications from "../components/App/useNotifications";
import useDemandes from "../components/App/useDemandes";
import API from "../api";
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import SvgIcon from '@mui/material/SvgIcon';
import useMyNotes from "../components/App/useMyNotes";
import useIdeas from "../components/App/useIdeas";

function HomeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
        </SvgIcon>
    );
}

export default function TopAppBar() {

    const {token, setToken} = useToken();
    const {user, setUser} = useUserData();
    const {setMyPlants} = useMyPlants();
    const {setShopPlants} = useShopPlants();
    const {setLibPlants} = useLibPlants();
    const {notifications, setNotifications} = useNotifications();
    const {setDemandes} = useDemandes();
    const {setIdeas} = useIdeas();
    const {setMyNotes} = useMyNotes();
    const {setGarden} = useGardenData();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    useEffect(() => {
        const fetchData = async () => {
            await API.get(`list_mynotifs?userid=${user.id}`)
                .then(res => {
                    if (res.data.status) {
                        const data = res.data.notifs;
                        if (data.length > 0) {
                            setNotifications(data);
                        }
                    }
                }).finally(res => {
                })
        }
        fetchData(user);
    }, [user]);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const navigate = useNavigate();

    const goToNotificationEventHandler = () => {
        navigate('/notification');
    }
    const goToDemandesEventHandler = () => {
        navigate('/demande-paysagist');
    }
    const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        goToNotificationEventHandler();
    };
    const handleDemandesMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        goToDemandesEventHandler();
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleLogout = () => {
        logout();

    };
    const logout = async () => {
        try {
            setMyPlants([]);
            setUser([]);
            setToken([]);
            setGarden([]);
            setShopPlants([]);
            setLibPlants([]);
            setNotifications([]);
            setDemandes([]);
            setIdeas([]);
            setMyNotes([]);


        } catch (e) {
            console.log('error logout');
        }
        navigate('/home');
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleLogoutMenu = () => {
        handleLogout();
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const goToIdeasAndTipsEventHandler = () => {
        navigate('/ideas-and-tips');
    }
    const handleIdeasAndTipsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        goToIdeasAndTipsEventHandler();
    };
    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const goToHomeHandler = () => {
        navigate('/home');
    }
    const handleHomeLink = (event: React.MouseEvent<HTMLElement>) => {
        goToHomeHandler();
    };
    if (!token) {
        return <Login/>
    }
    const menuId = 'primary-account-menu';
    const renderMenu = (
        <Menu className="fixedmenu" style={{margin: '0 auto',top:55}}
              anchorEl={anchorEl}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              id={menuId}
              keepMounted
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              open={isMenuOpen}
              onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Mon compte</MenuItem>
            <MenuItem onClick={handleLogoutMenu}>Se déconnecter</MenuItem>
        </Menu>
    );


    const mobileMenuId = 'primary-account-menu-mobile';
    const renderMobileMenu = (
        <Menu className="fixedmenu" style={{margin: '0 auto',top:55, right:0 }}
              anchorEl={mobileMoreAnchorEl}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              id={mobileMenuId}
              keepMounted
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              open={isMobileMenuOpen}
              onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleNotificationMenuOpen}>
                <IconButton
                    size="large"
                    color="inherit"
                >
                    <Badge badgeContent={notifications?.length ?? 0} color="error">
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
                <p>Rappels</p>
            </MenuItem>
            <MenuItem onClick={handleDemandesMenuOpen}>
                <IconButton
                    size="large"
                    color="inherit"
                ><QuestionAnswer/>
                </IconButton>
                <p>Consulter expert</p>
            </MenuItem>
            <MenuItem onClick={handleIdeasAndTipsMenuOpen}>
                <IconButton
                    size="large"
                    color="inherit"
                ><MarkUnreadChatAltOutlined/>
                </IconButton>
                <p>Conseils et idees</p>
            </MenuItem>
            <MenuItem onClick={handleLogoutMenu}>
                <IconButton
                    size="large"
                    aria-label="Logout"
                    aria-controls="primary-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <LogoutOutlined/>
                </IconButton>
                <p>Se déconnecter</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{flexGrow: 1}} style={{maxWidth: '480px', position: 'relative'}}>
            <AppBar position="static" style={{color: 'white', background: '#0c100dd9'}}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="white"
                        hidden={true}
                        aria-label="open drawer"
                        sx={{mr: 2}}
                    >
                        <MenuIcon style={{color: 'white'}}/>
                    </IconButton>
                    <IconButton
                        style={{backgroundColor: ' #f8db94', borderRadius: '30', width: 48, height: 48}}
                        size="large"
                        edge="start"
                        color="white"
                        hidden={false}
                        onClick={handleHomeLink}
                        aria-label="go home"
                        sx={{mr: 2}}
                    >
                        <HomeIcon style={{color: 'black'}} fontSize="large" />
                    </IconButton>
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        edge="start"
                        aria-label="go home"
                        sx={{mr: 1}} size="large"  onClick={handleHomeLink}
                    >
                      <img src={logo} className="App-logo2" alt="logo"/>
                    </IconButton>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'flex', md: 'flex'}}}>
                        <IconButton style={{backgroundColor: ' #f8db94', borderRadius: '30', color: 'black'}}
                                    size="large"
                                    aria-label="open drawer"
                                    aria-haspopup="true"
                                    aria-controls={mobileMenuId}
                                    onClick={handleMobileMenuOpen}
                                    color="white"
                        >
                            <MenuIcon style={{color: '#000'}}/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}
