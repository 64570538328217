import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Button, Divider, FormControl, InputLabel, Select, Stack, TextareaAutosize, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import IconButton from "@mui/material/IconButton";
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../../constants/styled-components";
import {planteCatNames} from "../../../constants/list-enum";
import API from '../../../api';
import useMyPlants from "../../App/useMyPlants";
import useGardenData from "../../App/useGardenData";
import {useNavigate} from "react-router";
import {makeStyles} from "@mui/styles";
import TopAppBar from "../../../partials/TopAppBar";
import './AddPlant.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const useStyles = makeStyles(() => {
    return {
        container: {
            padding: '2em'
        },
        field: {
            display: 'block',
            marginTop: '1em',
            marginBottom: '1em'
        },
        divider: {
            marginTop: '1em',
            marginBottom: '1em'
        }
    }
})

function AddPlant() {
    const classes = useStyles();
    const {garden, setGarden} = useGardenData();
    const [has_plants, setHasPlants] = useState(false);
    const [plant_id, setPlantId] = useState(null);
    const {myplants, setMyPlants} = useMyPlants();
    const [selectedImage, setSelectedImage] = useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category_id, setCategoryId] = useState("");


    let navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        name: {
            value: '',
            defaultValue: '',
            error: false,
            errorMessage: 'You must enter a name'
        },
        category_id: {
            value: 0,
            defaultValue: 0,
            error: false,
            errorMessage: 'You must enter an category'
        },
        description: {
            value: '',
            defaultValue: 'description',
            error: false,
            errorMessage: 'You must enter description'
        }
    })
// This function will be triggered when the file field change
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setDisabled(true);
            setSelectedImage(e.target.files[0]);
        }
    };
// This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
        setSelectedImage();
        setDisabled(false);
    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("photo", selectedImage);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("category_id", category_id);
        formData.append("type", 'lib');
        formData.append("garden_id", garden.id);

        const resp = await API.post('add_plant', formData, {
            headers: {
                "content-type": "multipart/form-data"
            },
        });


        if (resp.data) {
            if (resp.data.status) {
                setPlantId(resp.data.plant_id);
                setMyPlants(resp.data.data);
                navigate(`/plant-details/${resp.data.plant_id}`);
            } else {
                setHasPlants(false);
            }

        }
    }


    return (
        <div className="form-wrapper">
            <TopAppBar/>
            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>Ajouter une plante au biblio</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}} >

                    </Grid>
                </Grid>


            </Stack>
            <Divider component="h3" variant="inset"/>

            <div   style={{width: '100%',backgroundColor: 'rgb(225 231 202)',  padding: '10px' }}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
                autoComplete="off" onSubmit={handleSubmit}
                noValidate
            >
                <div>
                    <TextField className="inputAddText" required
                               name="name"
                               size="small"
                               onChange={(e) => setName(e.target.value)} value={name}
                               label={formValues.name.defaultValue}
                               error={formValues.name.error}
                               helperText={formValues.name.error && formValues.name.errorMessage}/>

                    <FormControl className="inputAddText" sx={{m: 1, minWidth: 250}} size="small">
                        <InputLabel id="demo-multiple-name-label">Category</InputLabel>
                        <Select required
                                size="small"
                                name="category_id"
                                label={formValues.category_id.defaultValue}
                                onChange={(e) => setCategoryId(e.target.value)} value={category_id}
                                error={formValues.category_id.error}
                        >
                            {planteCatNames.map((name, index) => (
                                <MenuItem
                                    key={index}
                                    value={index}
                                >{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextareaAutosize className="inputTextAria"
                                      aria-label="maximum height"
                                      placeholder="Maximum 4 rows"
                                      minRows={6}
                                      maxRows={6}

                                      onChange={(e) => setDescription(e.target.value)} value={description}
                                      name="description"
                                      label={formValues.description.defaultValue}/>


                    <div style={styles.container}>
                        <input className="inputimgplante"
                            accept="image/*"
                            type="file"
                            name="photo"
                            onChange={imageChange}
                            disabled = {disabled}
                        />
                        <AddAPhotoIcon style={{position: 'absolute', left: 20, top: 0, width: 100, height: 100}} />
                        {selectedImage && (
                            <div style={{position: 'absolute', left: 20, top: 0, width: 100, height: 100}} >
                            <div style={styles.preview} >
                                <IconButton  onClick={removeSelectedImage} style={styles.delete}>
                                    <CloseIcon />
                                </IconButton >
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    style={styles.image}
                                    alt="Thumb"
                                />
                            </div>
                            </div>
                        )}
                    </div>


                </div>
                <FormControl className={classes.field}>
                    <Button variant="contained" color="success" type="submit">Ajouter</Button>
                </FormControl>
            </Box>
           </div>

        </div>
    )
}

export default AddPlant;

// Just some styles
const styles = {
    container: {
        width: 100,
        height: 100,
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        paddingTop: 5,
        marginTop: 10,
        marginLeft: 20,
        background: '#f1f1f1',
        position: 'relative'

    },
    preview: {
        position: 'relative',
        maxWidth: 100,
        minHeight: 100,
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
    },
    image: {

        width: 110,
        height:110,
        position: 'absolute',
        top: 0, right: 0
    },
    delete: {
        zIndex: 10,
        position: 'absolute',
        top: 0, right: 0,
        width: 25, height: 25,
        cursor: "pointer",
        padding: 0,
        background: "#EEEEEE",
        color: "red",
        border: "none",
    },
};
