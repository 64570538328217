import React, {useEffect} from "react";
import {Button,   ListItemText, Stack} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import List from "@mui/material/List";
import {Grid, ListItem, ListItemContent} from "@mui/joy";
import {Itembreadcrumb} from "../../constants/styled-components";
import Typography from "@mui/material/Typography";
import moment from "moment";
import 'moment/locale/fr';
import useToken from "../App/useToken";
import Login from "../Login/Login";
import TopAppBar from "../../partials/TopAppBar";
import {useNavigate} from "react-router";
import useDemandes from "../App/useDemandes";
import API from "../../api";
import useUserData from "../App/useUserData";

function DemandePaysagist() {
    const {token} = useToken();
    const navigate = useNavigate();
    const {user} = useUserData();
    const {demandes, setDemandes} = useDemandes([]);
    const [is_loading, setIsLoading] = React.useState(false);
    const [has_demandes, setHasDemandes] = React.useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await API.get(`list_demands?userid=${user.id}`)
                .then(res => {
                    if (res.status) {

                        const data = res.data.demandes;
                        if (data.length > 0) {
                            setDemandes(data);
                            setHasDemandes(true);
                        }
                    }
                }).finally(res => {
                    setIsLoading(false);
                })
        }
        fetchData(user);
    }, [user, setHasDemandes, setIsLoading]);

    if (!token) {
        return <Login/>
    }
    return (

        <div className="home-wrapper">
            <TopAppBar/>

            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>Demander à un paysagiste</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}}>

                    </Grid>
                </Grid>


            </Stack>
            <div className="ideas" style={{marginTop: 2}}>
                <div style={{margin: '10px',  overflowY: 'scroll', maxHeight: '60vh'}} >
                    <List sx={{ width: '100%' }}>
                   {!is_loading && has_demandes ?
                        demandes.map((demande, index) => {
                             let coorbg='#FFFFFF' ;
                             if(demande.status === 'unread'){
                                 coorbg='#e9f3de' ;
                             }
                            return (
                                <ListItem   key={index} style={{marginTop: '10px', padding: '10px',  backgroundColor: coorbg, borderRadius: '0 15px'  }}>
                                    <ListItemContent style={{width: '20%',textAlign: 'left' }}>
                                        <img style={{width: '70px', height: '70px' }}
                                            src={`https://demo.sogreen.tn${demande.photos}`}
                                            srcSet={`https://demo.sogreen.tn${demande.photos}`}
                                            alt={demande.status}
                                            loading="lazy"
                                        />
                                    </ListItemContent>
                                    <ListItemText  style={{width: '70%',textAlign: 'left' }}
                                        primary={demande.demande}
                                        secondary={moment.utc(demande.created_at).format("Y-m-d  H:m:s")}
                                    />
                                </ListItem>


                            )
                        } )
                        :'' }
                    </List>
                </div>
            </div>


        </div>

    );
}
export default DemandePaysagist;
