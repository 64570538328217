import loader_notif from "../assets/bel_notif.gif";
import React from "react";

function LoaderNotif() {
    return (
        <div style={{marginTop: 20}}>
            <img src={loader_notif} alt="loader" width="390" height="321" />
        </div>
    );
}


export default LoaderNotif;


