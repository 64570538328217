import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import React , {useState} from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import useUserData from "../App/useUserData";
import {useNavigate} from "react-router-dom";
import {FormControl} from "@mui/joy";
import API from "../../api";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function AddDemandePaysagistBtn({gardenId}) {

    const garden_id = gardenId;
    const [demande, setDemande] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const {user} = useUserData();
    const navigate = useNavigate();
    const [disabled, setDisabled] = React.useState(false);

    // This function will be triggered when the file field change
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setDisabled(true);
            setSelectedImage(e.target.files[0]);
        }
    };

    // This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
        setSelectedImage();
        setDisabled(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("photo", selectedImage);
        formData.append("demande", demande);
        formData.append("user_id", user.id);
        formData.append("garden_id", garden_id);

        const resp = await API.post('add_demande', formData, {
            headers: {
                "content-type": "multipart/form-data"
            },
        });
       // console.log(resp.status)
        if (resp.data) {
            const demandedata = resp.data.data;
            navigate('/demande-paysagist', {
                state: {
                    Demandes: demandedata,
                }
            });

        }
    }
    return (
        <div>
            <Box sx={{ '& > :not(style)': { m: 1 } }} style={{position:'absolute',bottom:'10px',  width:'100%'}} className="fixedmenu"   variant="outlined" onClick={handleClickOpen}>

                <Fab variant="extended" size="medium" style={{backgroundColor:'#6D734D', color:'#EADEAA' }} color="" aria-label="add">
                    <NavigationIcon sx={{ mr: 1 }} />
                    Demander à un paysagiste
                </Fab>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                component="form"
                autoComplete="on" onSubmit={handleSubmit}
                noValidate
                className="fixedmenu"
                style={{margin:'0 auto' }}
            >
                <AppBar sx={{ position: 'relative' }} style={{backgroundColor:'#3a4932'}}>
                    <Toolbar>
                        <IconButton style={{color:'#ffffff'}}
                            edge="start"
                            color="#ffffff"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="rgb(234, 222, 170)">
                            Demander à un paysagiste
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List
                    style={{ backgroundColor:"#f6fff1"}}>

                    {/*<DialogTitle>Demander à un paysagiste</DialogTitle>*/}
                    <DialogContent style={{backgroundColor:"transparent"}}>
                        <DialogContentText>
                            pour consulter nos experts. Remplissez ce formulaire, ce la les aidera à poser un
                            diagnostic précis et à proposer un traitement
                        </DialogContentText>

                        <FormControl  sx={{m: 1, minWidth: 250, margin: '0px 10px'}} size="small">
                        <DialogTitle style={{fontSize:16, paddingLeft:0}}>Ajouter des images </DialogTitle>
                        <DialogContentText>
                            Joinez des photos nette de la plante entiére et des parties malades
                        </DialogContentText>
                        <div style={styles.container}>
                            <input  style={styles.inputimgdemande}
                                accept="image/*"
                                type="file"
                                name="photo"
                                onChange={imageChange}
                                    disabled = {disabled}
                            />
                            <AddAPhotoIcon style={{position: 'absolute', left: 20, top: 0, width: 100, height: 100}} />
                            {selectedImage && (
                                <div style={{position: 'absolute', left: 20, top: 0, width: 100, height: 100}} >
                                    <div style={styles.preview}>
                                        <button onClick={removeSelectedImage} style={styles.delete}>
                                            <CloseIcon />
                                        </button>
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            style={styles.image}
                                            alt="Thumb"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        </FormControl>
                        <FormControl  sx={{m: 1, minWidth: 250, margin: '0px 10px'}} size="small">
                        <DialogTitle style={{fontSize:16, paddingLeft:0}}>Décrivez le problème</DialogTitle>

                        <TextField
                            id="probleme"
                            label=""
                            name="probleme"
                            onChange= {(e) => setDemande(e.target.value)} value={demande}
                            multiline
                            rows={4}
                            placeholder="La semaine dernière les feuilles ont commencé a jaunire et ..."
                            style={{width:'100%'}}
                            variant="filled"
                        />

                        </FormControl>

                        <Divider />
                        <DialogActions>
                            <FormControl  sx={{m: 1 }} style={{ width:150, margin: '20px 5px', fontSize:12}} >
                                <Button style={{fontSize:12}} variant="contained"  color="warning" onClick={handleClose}>Annuler</Button>
                            </FormControl>
                            <FormControl  sx={{m: 1 }} style={{  width: 250, margin: '20px 5px', fontSize:12}} >
                                <Button  style={{fontSize:12}} variant="contained" color="success" type="submit" disabled={!(selectedImage && demande)}>Envoyer la demande</Button>
                            </FormControl>
                        </DialogActions>
                    </DialogContent>
                </List>
            </Dialog>
        </div>
    );
}
export default AddDemandePaysagistBtn;
// Just some styles
const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        paddingTop: 5,
        marginTop:10,
        minHeight:100,
        position: 'relative'

    },
    inputimgdemande: {
        opacity: 0,
        left: 20,
        top: 0,
        position: 'absolute',
        zIndex: 10,
        width: 100,
        height: 100

    },
    preview: {
        position:'relative',
        Width: 110,
        Height:110,
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
    },
    image: {
        width: 110,
        height:110,
        position:'absolute',
        top:0, right:0  },
    delete: {
        zIndex:10,
        position:'absolute',
        top:0, right:0,
        width: 25, height: 25,
        cursor: "pointer",
        padding: 0,
        background: "#EEEEEE",
        color: "red",
        border: "none",
    },
};
