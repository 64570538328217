
import React  from "react";
import moment from "moment";
import 'moment/locale/fr';
import {Masonry} from "@mui/lab";
import {ListItem, ListItemContent} from "@mui/joy";
import {ListItemText} from "@mui/material";
import imgremplacement from "../../assets/imgremplacement.svg";

function ListPlanteNote({dataNotes}) {
    const mynotes = dataNotes;

    const truncate = (input) =>
        input?.length > 100 ? `${input.substring(0, 80)}...` : input;
    return (

        <div className="container main-content" style={{marginTop: 20}}>
            <Masonry columns={1} spacing={1}>
                {mynotes ?
                    mynotes.map((note, index) => {

                        let coorbg='rgb(248 219 148)' ;
                        if(note.status === 'unread'){
                            coorbg='rgb(246 230 192)' ;
                        }
                        return (
                            <ListItem   key={index} style={{marginTop: '10px', padding: '10px',  backgroundColor: coorbg, borderRadius: '15px 0' }}>
                                <ListItemContent style={{width: '20%',textAlign: 'left' }}>
                                    <img style={{width: '70px', height: '70px' }}
                                         src={`https://demo.sogreen.tn${note.photos}`}
                                         srcSet={`https://demo.sogreen.tn${note.photos}`}
                                         alt={note.status}
                                         onError={e => {
                                             e.target.src = imgremplacement;
                                         }}
                                         loading="lazy"
                                    />
                                </ListItemContent>
                                <ListItemText  style={{width: '70%',textAlign: 'left',paddingLeft: 10 }}
                                               primary={truncate(note.note)}
                                               secondary = {moment.utc(note.created_at).format("Y-m-d  H:m:s")}
                                />
                            </ListItem>

                        )

                    })
                    :''
                }
            </Masonry>
            {/*</Grid>*/}
        </div>

    );
}
export default ListPlanteNote;
