import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {Divider, Grid, Stack} from "@mui/joy";
import Button from "@mui/material/Button";
import {Itembreadcrumb} from "../../constants/styled-components";
import API from '../../api';
import useToken from "../App/useToken";
import Login from "../Login/Login";
import TopAppBar from "../../partials/TopAppBar";
import useMyPlants from "../App/useMyPlants";
import PlantList from "./PlantList";
import useUserData from "../App/useUserData";
import useGardenData from "../App/useGardenData";
import Loader from "../../partials/Loader";
import {useNavigate} from "react-router";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddDemandePaysagistBtn from "./AddDemandePaysagistBtn";
import 'moment/locale/fr';
import './MyGarden.css';
import addplante from  '../../assets/addplante.png';
import { Search} from "@mui/icons-material";

function MyGarden() {

    const {token} = useToken();
    const {user} = useUserData();
    const {garden, setGarden} = useGardenData();
    const {myplants, setMyPlants} = useMyPlants();
    const [is_loading, setIsLoading] = useState(true);
    const [has_garden, setHasGarden] = useState(false);
    const [gardenid, setGardenId] = useState(user?.garden_id);
    const [searchInput, setSearchInput] = useState("");
    const [searchresults, setSearchresults] = useState(myplants);
    let navigate = useNavigate();
    useEffect( () => {
          async function fetchData() {
              if (gardenid) {
                  await API.get(`list_jardin?id=${gardenid}`)
                  .then(res => {
                      const data = res.data;
                      if (data.data) {
                          setHasGarden(true);
                          setGarden(data.data);
                          if (data.data.plants.length > 0) {
                              setMyPlants(data.data.plants);
                              setGardenId(data.data.id);
                          }
                      }
                  })
                  .finally(res => {
                      setIsLoading(false);
                  });
             }else{
                setIsLoading(false);
            }
          }
        fetchData();
    }, [ gardenid ]);
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
    if (!token) {
        return <Login/>
    }

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        if (searchInput.length ===0){
            setSearchresults(myplants) ;
        }
    };
    const handleKeyUp = (e) => {
        e.preventDefault();
        if (e.target.value.length ===0){
            setSearchresults(myplants) ;
        }
    };
    const handleSearch = (e) => {
        e.preventDefault();
        if(myplants){
            if (searchInput.length > 0) {
                const myReg = new RegExp(searchInput.toLowerCase()  + ".*")
                let li= myplants.filter((plant) => {
                    return  plant.name.toLowerCase().match(myReg) ;
                })
                setSearchresults(li) ;
            }
        }

    };

    const goToLibraryEventHandler = (garden) => (e) => {
        navigate(`/library`);
    }

    const goToAddGardenEventHandler = () => (e) => {
        navigate(`/add-garden`);
    }
    const goToGardenEventHandler = () => (e) => {
        navigate(`/garden-details`);
    }


    return (
        <>
           <div className="home-wrapper">
            <TopAppBar/>
            <Stack direction="row" className="breadcrumbnav" style={{padding:0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width:'100%', margin:0,padding:0}}>
                    <Grid item xs style={{padding:0}}>
                        <Itembreadcrumb style={{padding:0}}>
                            <Button className="btnback"
                                onClick={() => navigate(-1)}
                                startIcon={<ChevronLeftIcon   style={{width:'50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}} />}   > </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding:0}}>
                        <Itembreadcrumb style={{padding:0}}>
                            {!is_loading ?
                            <>
                                {has_garden
                                    ?
                                    <Button  onClick={goToGardenEventHandler()}><Typography gutterBottom variant="h6" component="div" style={{color: '#f8db94'}}>{garden.name} </Typography></Button> : <></>
                                }
                            </>: ''
                        }</Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding:0}}>
                        <Itembreadcrumb style={{padding:0}}>
                            {!is_loading ?
                            <>
                                {has_garden
                                    ? <Button className="addback"  style={{ backgroundColor: '#607556', textAlign:'center',marginRight: 0 ,marginLeft: 0  }}
                                              variant="contained" onClick={goToLibraryEventHandler(garden)} >
                                        <img src={addplante}  style={{width:'48px', height: '30px', margin: '0 auto'}} alt="Ajouter plante"/>
                                    </Button>
                                    : ''
                                }
                            </>: ''
                            }
                       </Itembreadcrumb>
                    </Grid>
                </Grid>



            </Stack>
            <div style={{marginTop: 20}}>

                {!is_loading ?
                    <>
                        {!has_garden
                            ? <ul className="home_btn-rub">
                                <li className="btn-rub">
                                    <Button  onClick={goToAddGardenEventHandler()}>Créer votre Jardin</Button>
                                    <div>
                                        <Divider component={'div'} variant="middle"
                                                 style={{width: '90%', marginTop: 15, marginBottom: 15}}/>

                                    </div>
                                </li>
                              </ul>
                            : <>
                                <div style={{   maxHeight: '100vh'}}>
                                    <div id="plants_list" style={{  overflowY: 'scroll', maxHeight: '60vh'}}>
                                        {searchresults ?
                                            <div className="searchbar" style={{ position:'relative'}}>
                                                <input className='search'
                                                   type="search"
                                                   placeholder="Search here"
                                                   onChange={handleChange}
                                                   onKeyUp={handleKeyUp}
                                                   value={searchInput} />
                                                <button className='search-button' style={{
                                                    position:'absolute',
                                                    top:10,
                                                    right: 30 ,
                                                    borderRadius:30,
                                                    border: 'none'
                                                }}
                                                        onClick={handleSearch}>
                                                    <Search />
                                                </button>
                                            </div>
                                            :''
                                        }
                                        <PlantList dataParentToChild={searchresults}/>

                                    </div>
                                </div>
                            </>
                        }
                    </>
                    :<Loader/>

                }
            </div>
               {garden
                   ? <AddDemandePaysagistBtn gardenId={garden.id} />:<></>
               }



           </div>


        </>
    );

}
export default MyGarden;
