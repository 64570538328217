import React, {useEffect, useState} from 'react';
import {Button, Divider,  Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../constants/styled-components";
import useToken from "../App/useToken";
import Login from "../Login/Login";
import TopAppBar from "../../partials/TopAppBar";
import API from "../../api";
import useLibPlants from "../App/useLibPlants";
import PlantList from "./PlantList";
import Loader from "../../partials/Loader";
import {useNavigate} from "react-router";
import './Library.css';
import addplante from "../../assets/addplante.png";

function Library(){
    const {token} = useToken();
    const [is_loading, setIsLoading] = useState(true);
    const [has_plants, setHasPlants] = useState(false);
    const {libplants, setLibPlants} = useLibPlants();

    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            await  API.get(`list_libplante`)
                .then(res => {
                    const data = res.data;
                    if (data.plants) {
                        if (data.plants.length>0) {
                            setLibPlants(data.plants);
                            setHasPlants(true);
                        }
                    }
                }).finally(res => {
                    setIsLoading(false);
                })
        }
        fetchData();
    },[]);

    if (!token) {
        return <Login/>
    }

    const goToAddPlantEventHandler = () => (e) => {
        navigate(`/add-plant`);
    }

    return (
            <div className="home-wrapper">
                <TopAppBar/>

                <Stack direction="row" className="breadcrumbnav" style={{padding:0}}>

                    <Grid container spacing={3}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center" style={{width:'100%', margin:0,padding:0}}>
                        <Grid item xs style={{padding:0}}>
                            <Itembreadcrumb style={{padding:0}}>
                                <Button className="btnback"
                                        onClick={() => navigate(-1)}
                                        startIcon={<ChevronLeftIcon   style={{width:'50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}} />}   > </Button>
                            </Itembreadcrumb>
                        </Grid>
                        <Grid item xs={6} style={{padding:0}}>
                            <Itembreadcrumb style={{padding:0}}>
                                           <Typography gutterBottom variant="h6" component="div" style={{color: '#f8db94'}}>Biblio des plantes</Typography>
                            </Itembreadcrumb>
                        </Grid>
                        <Grid item xs style={{padding:0}}>
                            <Itembreadcrumb style={{padding:0}}>
                            <Button className="addback"  style={{ backgroundColor: '#607556', textAlign:'center',marginRight: 0 ,marginLeft: 0   }}
                                    variant="contained" onClick={goToAddPlantEventHandler()} >
                                <img src={addplante}  style={{width:'48px', height: '30px', margin: '0 auto'}} alt="Ajouter plante"/>
                            </Button>
                            </Itembreadcrumb>
                        </Grid>
                    </Grid>



                </Stack>




                    {!is_loading
                        ? <div style={{marginTop: 20, height:'100vh'}}>
                            <div id="plants_list" hidden={!has_plants} style={{  overflowY: 'scroll', maxHeight: '80vh'}}>
                                <Divider component="h3" variant="inset"/>
                                <PlantList  dataParentToChild = {libplants} />
                            </div>
                          </div>
                        :<Loader/>
                    }


            </div>
        );

}

export default Library;
