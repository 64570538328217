import useToken from "../App/useToken";
import React, {useEffect} from "react";
import {Button, Stack} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {Grid} from "@mui/joy";
import {Itembreadcrumb} from "../../constants/styled-components";
import Typography from "@mui/material/Typography";
import Login from "../Login/Login";
import TopAppBar from "../../partials/TopAppBar";
import {useNavigate} from "react-router";
import Article from "./Article";
import API from "../../api";
import useIdeas from "../App/useIdeas";
import imgremplacement from "../../assets/imgremplacement.svg";

function IdeasAndTips() {
    const {token} = useToken();
    let navigate = useNavigate();

    const {ideas, setIdeas} = useIdeas([]);
    const [is_loading, setIsLoading] = React.useState(false);
    const [has_ideas, setHasIdeas] = React.useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await API.get(`list_ideas`)
                .then(res => {
                    if (res.status) {
                        const data = res.data.ideas;
                        if (data.length > 0) {
                            setIdeas(data);
                            setHasIdeas(true);
                        }
                    }
                }).finally(res => {
                    setIsLoading(false);
                })
        }
        fetchData();
    }, [setHasIdeas, setIsLoading]);


    if (!token) {
        return <Login/>
    }
    const loadImg = (url) =>{
        let  imageC='';
        if(url) {
            if(url.toLowerCase().includes("http://")   || url.toLowerCase().includes("https://") ){
                imageC=url
            }else{
                imageC=`https://demo.sogreen.tn${url}`
            }
        }
        return imageC;
    };
    return (

        <div className="home-wrapper">
            <TopAppBar/>


            <Stack direction="row" className="breadcrumbnav" style={{padding: 0}}>

                <Grid container spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center" style={{width: '100%', margin: 0, padding: 0}}>
                    <Grid item xs style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Button className="btnback"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ChevronLeftIcon style={{width: '50px', height: '50px', borderRadius: 30,  backgroundColor: '#607556'}}/>}> </Button>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs={6} style={{padding: 0}}>
                        <Itembreadcrumb style={{padding: 0}}>
                            <Typography gutterBottom variant="h6" component="div"
                                        style={{color: '#f8db94'}}>Conseils et idées</Typography>
                        </Itembreadcrumb>
                    </Grid>
                    <Grid item xs style={{padding: 0}}> </Grid>
                </Grid>


            </Stack>
            <div className="ideas" style={{marginTop: 2}}>
                <div style={{margin: '10px 5px',padding: '0px 10px', overflowY: 'scroll', maxHeight: '490px'}}>
                    <Grid container spacing={1}>
                        {!is_loading && has_ideas ?
                            ideas.map((item, index) => {
                              return (
                                <Grid key={index} item xs={6} md={5}>
                                    <Article title={item.titre}
                                             desc={item.description}
                                             img={loadImg(item.photos)}
                                             onError={e => {
                                                 e.target.src = imgremplacement;
                                             }}  />
                                </Grid>
                               )
                            })
                           :'' }

                    </Grid>

                </div>
            </div>


        </div>

    );
}

export default IdeasAndTips;
