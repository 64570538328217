import {useState} from 'react';

export default function useLibPlants() {
    const getLibPlants = () => {
        const libplantsString = localStorage.getItem('lib_plants');
        return JSON.parse(libplantsString)
    };

    const [libplants, setLibPlants] = useState(getLibPlants());

    const saveLibPlants = userLibPlants => {
        localStorage.setItem('lib_plants', JSON.stringify(userLibPlants));
        setLibPlants(userLibPlants );
    };

    return {
        setLibPlants: saveLibPlants,
        libplants
    }
}