import {useState} from "react";

export default function useGardenData() {
    const getGarden = () => {
        const gardenDataString = localStorage.getItem('my_garden');
        return JSON.parse(gardenDataString)
    };

    const [garden, setGarden] = useState(getGarden());

    const saveGarden = gardenData => {
        localStorage.setItem('my_garden', JSON.stringify(gardenData));
        setGarden(gardenData);
    };

    return {
        setGarden: saveGarden,
        garden
    }
}